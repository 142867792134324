import React from "react";
import funding from "../assets/images/icons/funding.svg";
import costEffective from "../assets/images/icons/cost-effective.svg";
import streamlinedSales from "../assets/images/icons/streamlined-sales.svg";
import verifyContact from "../assets/images/icons/verify-contact.svg";
import customizableLeads from "../assets/images/icons/customizable-leads.svg";
import integration from "../assets/images/icons/integration.svg";

const fiWhyRevliData = [
  {
    img: funding,
    title: "Direct Access to Funding Data",
    subTitle:
      "Get immediate access to the latest funding rounds and continuously updated lists of high-value leads.",
  },
  {
    img: costEffective,
    title: "Cost-Effective Market Research",
    subTitle:
      "Eliminate expensive subscriptions with a single, comprehensive source of recent funding and contact data.",
  },
  {
    img: streamlinedSales,
    title: "Streamlined Sales Prospecting",
    subTitle:
      "Reduce time spent on research and prospecting by focusing directly on high-potential, well-funded leads.",
  },
  {
    img: verifyContact,
    title: "Verified Contact Details",
    subTitle:
      "Access verified contact data, including emails and LinkedIn profiles, ensuring accuracy and reliability.",
  },
  {
    img: customizableLeads,
    title: "Customizable Lead Lists",
    subTitle:
      "Easily filter your lead lists to only target companies and contacts that perfectly match your business needs.",
  },
  {
    img: integration,
    title: "Seamless Integration",
    subTitle:
      "Easily integrate our data into your CRM and sales tools for a simple transition to outbound marketing.",
  },
];

const FIWhyChooseRevli = () => {
  return (
    <section className="pt-[3.875rem] pb-20">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl lg:text-[2.875rem] font-extrabold line-height3 text-center">
          Why choose Revli?
        </h2>
        <div className="mt-10 lg:mt-[7rem] relative">
          <ul className="flex lg:flex-wrap -mx-3 overflow-x-auto pt-8 lg:pt-0 lg-overflow-x-initial scrollbar-none overflow-x-behaviour-contain">
            {fiWhyRevliData.map((item, index) => (
              <li
                key={index}
                className="w-11/12 lg:w-4/12 flex-shrink-0 lg-flex-shrink-initial px-3 mb-6"
              >
                <div className="h-full card-box">
                  <figure className="fig-wrapper">
                    <img src={item.img} alt="icon-telegram" />
                  </figure>
                  <div className="mt-8 lg:mt-[3.375rem] flex flex-col">
                    <h5 className="text-lg lg:text-xl font-bold">
                      {item.title}
                    </h5>
                    <p className="secondary-font text-secondary lg:text-lg letter-space02">
                      {item.subTitle}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="lg:w-9/12 mx-auto flex flex-col items-center justify-center mt-20 pb-20 border-b border-black border-opacity-20">
          <h3 className="text-2xl md:text-4xl lg:text-[2.75rem] font-extrabold letter-space02">
            Unlock Your Sales Potential
          </h3>
          <button
            className="btn btn--primary btn--h60 lg:text-[1.438rem] px-8 py-2 mt-6"
            onClick={() =>
              (window.location.href = "https://app.revli.com/register")
            }
          >
            Get Your First Report Free
          </button>
          <span className="lg:text-lg font-medium letter-space02 mt-4">
            No credit card required.
          </span>
        </div>
      </div>
    </section>
  );
};

export default FIWhyChooseRevli;
