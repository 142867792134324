import React from "react";
import ellipseHeroPic from "../assets/images/ellipse-hero-bg.svg";
import heroBanner from "../assets/images/home-hero-banner.webp";

const HeroHome = () => {
  return (
    <section className="bg-hero pt-[7rem] sm:pt-[10rem] lg:pt-[14rem] pb-8 lg:pb-0">
      <div className="relative container mx-auto flex flex-col lg:flex-row z-[1]">
        <div className="lg:w-6/12 xl:w-5/12 pr-6 pt-6">
          <h1 className="text-white text-4xl md:text-5xl lg:text-6xl xl:text-[4rem] font-bold lg-line-height3">
            Find and close <br></br>
            your next deal
          </h1>
          <p className="text-white text-base md:text-xl mt-4 letter-space02 opacity-70">
            Simple and affordable sales solutions to help you find new customers
            and close more deals in less time.
          </p>
          <div className="flex flex-col text-center mt-5 lg:mt-10 w-full md:w-6/12">
            <button
              className="btn btn--primary lg:text-[1.375rem] font-bold py-[0.594rem] px-5"
              onClick={() =>
                (window.location.href = "https://app.revli.com/register")
              }
            >
              Get Started Free
            </button>
            <span className="secondary-font text-sm lg:text-base text-white font-extra-bold mt-2">
              No credit card required.
            </span>
          </div>
        </div>
        <figure className="relative w-full lg:w-[45%] xl:w-[53%] lg:-top-[5rem] xl:-top-[7rem] mt-5 lg:mt-0">
          <img
            src={heroBanner}
            alt="home-hero-banner"
            className="max-w-full lg:max-w-[50rem] xl:max-w-[73rem]"
          />
        </figure>
        {/* <figure className="hero-home__banner-pic">
          <img src={heroInboxPerformance} alt="hero-inbox-performance" />
        </figure> */}
        {/* <div style={{ height: "44.125rem" }}>
          <img
            src={heroBanner}
            alt="hero-banner"
            className="absolute top-[6.875rem] right-0 z-[1]"
            style={{ width: "54.688rem", height: "44.125rem" }}
          />
        </div> */}
      </div>

      <img
        src={ellipseHeroPic}
        alt="ellipse-hero-bg"
        className="hidden lg:block absolute top-0 right-0"
      />
    </section>
  );
};

export default HeroHome;
