import React, { useState, useEffect } from "react";
import CHOICES from "../CHOICES";
import HeroCommon from "../components/common/hero-section";
import Loader from "../components/common/Loader";
import Footer from "../components/Footer";
import { fetchQuery } from "../relay";
import { GetDynamicContentQuery } from "../service/query/GetDynamicContentQuery";
import HeaderNew from "../components/HeaderNew";

const Terms = () => {
  const [termsAndCondition, setTermsAndCondition] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchQuery(GetDynamicContentQuery, {
      kind: CHOICES.DynamicContentType.TERM_CONDITION,
    })
      .toPromise()
      .then((data) => {
        if (data?.getContent) {
          setLoading(false);
          setTermsAndCondition(data?.getContent?.context);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="">
      <section className="terms">
        {loading && <Loader />}
        <HeaderNew />
        <HeroCommon title={"Terms & Conditions"} />
        <section className="relative pb-[6.25rem] -mt-[200px]">
          <div className="main-container">
            <div
              className="card-section p-8 card-section-privacy text-justify"
              style={{ minHeight: 500 }}
            >
              <p
                className="blog__content blog__content--lg mt-4 lg:mt-12"
                dangerouslySetInnerHTML={{
                  __html: termsAndCondition,
                }}
              ></p>
            </div>
          </div>
        </section>
        <Footer />
      </section>
    </main>
  );
};

export default Terms;
