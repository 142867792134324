import React, { useEffect } from 'react'
import { useLocation} from "react-router-dom";
import {update} from './Intercom';
function ListenRoute() {
  const location = useLocation();
  useEffect(()=>{
    // console.log('listening')
    update();
  },[location])
  return (
    <></>
  )
}

export default ListenRoute