import React, { useState, useEffect } from "react";
import CHOICES from "../CHOICES";
import HeroCommon from "../components/common/hero-section";
import Loader from "../components/common/Loader";
import Footer from "../components/Footer";
import { fetchQuery } from "../relay";
import { GetDynamicContentQuery } from "../service/query/GetDynamicContentQuery";
import HeaderNew from "../components/HeaderNew";

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchQuery(GetDynamicContentQuery, {
      kind: CHOICES.DynamicContentType.PRIVACY_POLICY,
    })
      .toPromise()
      .then((data) => {
        if (data?.getContent) {
          setLoading(false);
          setPrivacyPolicy(data?.getContent?.context);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="">
      <section className="privacy-policy">
        {loading && <Loader />}
        <HeaderNew />
        <HeroCommon title={"Privacy Policy"} />
        <section className="relative pb-[6.25rem] -mt-[200px]">
          <div className="main-container">
            <div
              className="card-section p-8 card-section-privacy text-justify"
              style={{ "min-height": 500 }}
            >
              <p
                className="blog__content blog__content--lg mt-4 lg:mt-12"
                dangerouslySetInnerHTML={{
                  __html: privacyPolicy,
                }}
              ></p>
              {/* <p className="font-medium">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled
              </p>
              <p className="font-medium mt-4">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled
              </p> */}
            </div>
          </div>
        </section>

        <Footer />
      </section>
    </main>
  );
};

export default PrivacyPolicy;
