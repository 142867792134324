import React from "react";
import weeklyReport from "../assets/images/fi-weekly-report.svg";
import verifiedEmails from "../assets/images/fi-verified-emails.svg";
import dataPoints from "../assets/images/fi-data-points.svg";
import companyProfiles from "../assets/images/fi-company-profiles.svg";
import crmIntegration from "../assets/images/fi-crm-integration.svg";
import accurateData from "../assets/images/fi-accurate-data.svg";

const fiWeeklyData = [
  {
    picture: weeklyReport,
    title: "Latest Funding Insights",
    desc: "Stay ahead of the curve with weekly reports on the latest funding news and data, ensuring you always have the most current information to target your outreach efforts.",
  },
  {
    picture: verifiedEmails,
    title: "Verified Emails and LinkedIn",
    desc: "Each week, receive hundreds of verified leads, including emails and LinkedIn profiles of founders, CEO's and other key-decision makers at newly funded companies.",
  },
  {
    picture: dataPoints,
    title: "20+ Precision Data Points",
    desc: "Refine your targeting and outreach using accurate and detailed company and prospect data that includes over 20 attributes per lead.",
  },
  {
    picture: companyProfiles,
    title: "Comprehensive Company Profiles",
    desc: "Access detailed profiles on each company, including industry, revenue, company size and recent funding amount, to better understand potential clients and tailor your strategies.",
  },
  {
    picture: crmIntegration,
    title: "Simple CRM Integration",
    desc: "Easily export and integrate company and contact data into your existing CRM for a smooth workflow and immediate outreach capability.",
  },
  {
    picture: accurateData,
    title: "Up-To-Date and Accurate Data",
    desc: "Access the most accurate and high-quality funding data to keep you ahead in a fast-paced market. Each list is hand-built and verified through countless hours of manual research.",
  },
];

const FIWeeklyReport = () => {
  return (
    <section className="pt-[3.875rem] relative">
      <div className="container mx-auto">
        <h2 className="text-3xl lg:text-[2.875rem] font-extrabold line-height3 text-center">
          What’s in your weekly report
        </h2>
        <p className="secondary-font text-lg lg:text-xl font-medium text-center mt-4">
          Comprehensive company and contact data with verified emails and
          LinkedIn profiles.<br className="hidden lg:block"></br> Updated and
          delivered every week.
        </p>
      </div>
      <div className="lg:w-11/12 mx-auto mt-10 lg:mt-[7rem] px-6">
        <div className="flex lg:flex-wrap justify-center flex-col lg:flex-row -mx-6">
          {fiWeeklyData.map((item, index) => (
            <div key={index} className="lg:w-5/12 px-6 mb-20 last:mb-0">
              <div className="flex flex-col">
                <img
                  src={item.picture}
                  alt=""
                  className="w-full xl:h-[19.563rem]"
                />
                <div className="mt-8">
                  <h3 className="text-xl lg:text-3xl font-bold">
                    {item.title}
                  </h3>
                  <p className="text-secondary lg:text-lg letter-space02 mt-4">
                    {item.desc}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FIWeeklyReport;
