import React from "react";
import growthStrategies from "../assets/images/icons/data-driven.svg";
import readyAppointment from "../assets/images/icons/ready-appointments.svg";
import customizedCampaignDesign from "../assets/images/icons/customized-campaign-design.svg";
import decisionMaking from "../assets/images/icons/data-driven-decision-making.svg";
import teamIntegration from "../assets/images/icons/team-integration.svg";
import focusedApproach from "../assets/images/icons/focused-approach.svg";

const MeetingSchedulingWhyChooseRevli = () => {
  const MsWhyRevliData = [
    {
      img: growthStrategies,
      title: "Innovative Growth Strategies",
      desc: "Our strategies are designed to scale with your startup. From concept to market leader, we guide you through each growth phase.",
    },
    {
      img: readyAppointment,
      title: "Revenue-Ready Appointments",
      desc: "We don’t just schedule calls; we open doors to growth by connecting you with leads that are primed and ready to discuss business.",
    },
    {
      img: customizedCampaignDesign,
      title: "Customized Campaign Design",
      desc: "Our campaigns are as unique as your startup, crafted to communicate your innovative solutions to your ideal customer profile.",
    },
    {
      img: decisionMaking,
      title: "Data-Driven Decision Making",
      desc: "We make informed moves with insights gleaned from robust data analytics, aligning your product with market needs and trends.",
    },
    {
      img: teamIntegration,
      title: "Collaborative Team Integration",
      desc: "Our methods integrate with your team's workflow, supporting a collaborative environment for peak sales performance.",
    },
    {
      img: focusedApproach,
      title: "Results-Focused Approach",
      desc: "We implement strategies with a direct line to revenue impact, focusing on high-value activities that drive business growth. ",
    },
  ];
  return (
    <section className="py-[3.875rem] relative">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl lg:text-[2.875rem] font-extrabold line-height3 text-center">
          Why choose <span className="text-primary-brand-dark">Revli?</span>
        </h2>
        <div className="mt-10 lg:mt-[7rem] relative">
          <ul className="flex lg:flex-wrap -mx-3 overflow-x-auto pt-8 lg:pt-0 lg-overflow-x-initial scrollbar-none overflow-x-behaviour-contain">
            {MsWhyRevliData.map((item, index) => (
              <li
                key={index}
                className="w-11/12 lg:w-4/12 flex-shrink-0 lg-flex-shrink-initial px-3 mb-6"
              >
                <div className="h-full card-box">
                  <figure className="fig-wrapper">
                    <img src={item.img} alt="icon-telegram" />
                  </figure>
                  <div className="mt-10 lg:mt-[3.375rem] flex flex-col">
                    <h5 className="text-lg lg:text-xl font-bold">
                      {item.title}
                    </h5>
                    <p className="secondary-font text-secondary lg:text-lg mt-[0.625rem] letter-space02">
                      {item.desc}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default MeetingSchedulingWhyChooseRevli;
