import React from "react";
import Footer from "../components/Footer";
import HeaderNew from "../components/HeaderNew";
import findCustomer from "../assets/images/find-customer.svg";
import craftingStrategy from "../assets/images/crafting-strategies.svg";
import customerAnalysis from "../assets/images/customer-analysis.svg";
import multichannelOutreach from "../assets/images/multichannel-outreach.svg";
import meetingScheduling from "../assets/images/meeting-scheduling1.svg";
import campaignMonitoring from "../assets/images/campaign-monitoring.svg";
import FAQ from "../components/FAQ";
import CHOICES from "../CHOICES";
import Testimonials from "../components/Testimonials";
import GetStarted from "../components/GetStarted";
import MeetingScheduleFeatures from "../components/MeetingScheduleFeatures";
import MeetingScheduleQualifiedMeetings from "../components/MeetingScheduleQualifiedMeetings";
import MeetingSchedulingWhyChooseRevli from "../components/MeetingSchedulingWhyChooseRevli";
import polygonBgBanner from "../assets/images/polygon-bg-banner2.svg";

const MeetingSchedule = () => {
  const leadCriteriaData = [
    "Industry & Keywords",
    "Contact Location",
    "Company Location",
    "Company Size",
    "Company Revenue",
    "Job titles",
    "Buying Intent",
    "Technographics",
    "And more!",
  ];
  const preciseaData = [
    "Verified Emails",
    "Personal LinkedIn Profiles",
    "Company Website",
    "Company Social Profiles",
    "Company Phone Numbers",
    "Company Tech Stack",
    "Number Of Employees",
    "Company & Contact Location",
    "And more!",
  ];
  return (
    <main className="">
      <section className="meeting-schedule">
        <HeaderNew />
        <section className="relative lg:min-h-screen xxl:min-h-full bg-hero pt-[7rem] sm:pt-[10rem] lg:pt-[13.5rem] pb-16 lg:pb-10 hero-banner2">
          <div className="relative container mx-auto flex flex-col items-center justify-center z-[1]">
            <h1 className="text-white text-3xl md:text-5xl lg:text-6xl xl:text-7xl font-bold lg-line-height3 text-center">
              Stop prospecting.<br></br>
              Start closing.
            </h1>
            <p className="text-white text-base md:text-xl text-center mt-4 letter-space02 opacity-70">
              We generate, you convert.<br className="hidden lg:block"></br>
              High-quality demos and meetings delivered.
            </p>
            <div className="flex flex-col text-center mt-10">
              <button
                className="btn btn--primary btn--h60 text-lg lg:text-2xl font-bold py-[0.594rem] px-16 capitalize"
                onClick={() => {
                  window.open("https://calendly.com/revli", "_blank");
                }}
              >
                Schedule A Call
              </button>
              <span className="secondary-font text-white font-extra-bold text-xs mt-2">
                Schedule your free consultation.
              </span>
            </div>
          </div>
          <img
            src={polygonBgBanner}
            alt="polygon-bg-banner"
            className="absolute bottom-0 left-0 right-0 mx-auto h-3/4"
          />
        </section>
        <section className="bg-white pt-16 lg:pt-20 pb-[3.875rem] lg:pb-[7.75rem]">
          <div className="container mx-auto flex justify-between flex-col lg:flex-row">
            <div className="lg:w-5/12">
              <h2 className="text-3xl lg:text-[2.75rem] font-bold lg-line-height3">
                Supercharge your pipeline with meetings that matter.
              </h2>
            </div>
            <div className="lg:w-6/12 lg:pr-12 mt-8 lg:mt-0">
              <h3 className="text-2xl lg:text-[1.625rem] font-bold line-height3">
                Revli brings you qualified sales meetings, so you can focus on
                closing the deal.
              </h3>
              <hr className="w-[4.563rem] border-b-2 border-primary-brand mt-3"></hr>
              <p className="text-secondary text-lg lg:text-xl letter-space02 mt-4">
                We take prospecting and meeting scheduling off your plate so you
                can stay focused on what matters most.
              </p>
            </div>
          </div>
        </section>
        <section className="bg-section1 pt-[3.875rem] lg:pt-[7.5rem] pb-16 lg:pb-20">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row justify-center">
              <img src={craftingStrategy} alt="craft-strategy" />
              <div className="lg:w-6/12 mt-10 lg:pl-5 xl:pl-20">
                <h3 className="text-2xl lg:text-3xl xl:text-4xl font-bold xl:pr-16">
                  Boost your sales with expert-crafted strategies.
                </h3>
                <p className="text-secondary text-lg lg:text-xl letter-space02 mt-4 xl:pr-5">
                  Transform your strategy from generic to genius with our team
                  of B2B SaaS experts. We zero in on your audience, then build
                  and execute a sales process that delivers high quality
                  meetings and ready-to-close deals.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-[3.875rem] lg:pt-[7.5rem] pb-16 lg:pb-20">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row justify-center">
              <div className="lg:w-5/12 mt-10 lg:pr-5">
                <h3 className="text-2xl lg:text-3xl xl:text-4xl font-bold">
                  Win bigger deals and find customers that convert.
                </h3>
                <p className="text-secondary text-lg lg:text-xl letter-space02 mt-8 lg:mt-4">
                  Open up new sales opportunities with our precision-targeting.
                  We connect you with prospects who are ready to engage,
                  ensuring that every meeting is a step towards a significant
                  deal.
                </p>
              </div>
              <img
                src={findCustomer}
                alt="find-customer-pic"
                className="-order-[1] lg:order-1"
              />
            </div>
          </div>
        </section>
        <MeetingScheduleFeatures />
        <section className="py-[3.875rem] lg:py-[7rem] bg-section2">
          <div className="container mx-auto">
            <h2 className="text-3xl lg:text-[2.875rem] font-extrabold line-height3 text-center">
              How it works
            </h2>
            <p className="secondary-font text-lg lg:text-xl font-medium text-center mt-4">
              We've got you covered on each step of the process to bring you
              ready-to-buy leads and meetings.
            </p>
            <div className="stepper-wrapper mt-10 lg:mt-20">
              <div className="stepper__list flex items-center justify-between flex-col lg:flex-row pb-[6.5rem]">
                <div className="lg:w-5/12 lg:pr-8">
                  <h3 className="text-xl lg:text-[2rem] font-bold lg-line-height3">
                    Customer analysis and market research
                  </h3>
                  <ul className="mt-6 ml-3">
                    <li className="text-secondary letter-space02 list-disc mb-4">
                      First, we review and segment customer data to identify key
                      markets and target audiences
                    </li>
                    <li className="text-secondary letter-space02 list-disc mb-4">
                      Next, we analyze market trends and customer behaviors to
                      tailor strategies effectively.
                    </li>
                    <li className="text-secondary letter-space02 list-disc mb-4">
                      Finally, we gather insights on competitors and industry
                      benchmarks to refine your positioning.
                    </li>
                  </ul>
                </div>
                <div className="lg:w-5/12 lg:pl-8 -order-[1] lg:order-[1] mb-8 lg:mb-0">
                  <img src={customerAnalysis} alt="customer-analysis" />
                </div>
              </div>
              <div className="stepper__list flex items-center justify-between flex-col lg:flex-row pb-[6.5rem]">
                <div className="lg:w-5/12 lg:pr-8">
                  <img src={multichannelOutreach} alt="multichannel-outreach" />
                </div>
                <div className="lg:w-5/12 lg:pl-8 mt-8 lg:mt-0">
                  <h3 className="text-xl lg:text-[2rem] font-bold lg-line-height3">
                    Multichannel outreach launch
                  </h3>
                  <ul className="mt-6 ml-3">
                    <li className="text-secondary letter-space02 list-disc mb-4">
                      Based on your ICP and research data, we develop an
                      outreach strategy and prepare templates of personalized
                      emails or LinkedIn messages.
                    </li>
                    <li className="text-secondary letter-space02 list-disc mb-4">
                      We initiate targeted outreach campaigns that test
                      messaging and channel efficacy, refining our approach
                      based on data insights.
                    </li>
                    <li className="text-secondary letter-space02 list-disc mb-4">
                      After launching campaigns, we track responses and
                      interactions and adjust strategy if needed.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="stepper__list flex items-center justify-between flex-col lg:flex-row pb-[6.5rem]">
                <div className="lg:w-5/12 lg:pr-8">
                  <h3 className="text-xl lg:text-[2rem] font-bold lg-line-height3">
                    Meeting scheduling
                  </h3>
                  <ul className="mt-6 ml-3">
                    <li className="text-secondary letter-space02 list-disc mb-4">
                      We coordinate schedules and set up meetings between your
                      sales team and qualified leads.
                    </li>
                    <li className="text-secondary letter-space02 list-disc mb-4">
                      We use automated tools to manage invitations,
                      confirmations, and reminders to ensure high attendance
                      rates.
                    </li>
                    <li className="text-secondary letter-space02 list-disc mb-4">
                      In case of a no-show, we follow up with prospects to
                      reschedule and adapt scheduling strategies to maximize
                      engagement.
                    </li>
                  </ul>
                </div>
                <div className="lg:w-5/12 lg:pl-8 -order-[1] lg:order-[1] mb-8 lg:mb-0">
                  <img src={meetingScheduling} alt="meeting-scheduling" />
                </div>
              </div>
              <div className="stepper__list flex items-center justify-between flex-col lg:flex-row">
                <div className="lg:w-5/12 lg:pr-8">
                  <img src={campaignMonitoring} alt="campaign-monitoring" />
                </div>
                <div className="lg:w-5/12 lg:pl-8 mt-4 lg:mt-0">
                  <h3 className="text-xl lg:text-[2rem] font-bold lg-line-height3">
                    Campaign monitoring and optimization
                  </h3>
                  <ul className="mt-6 ml-3">
                    <li className="text-secondary letter-space02 list-disc mb-4">
                      We continuously track campaign performance through key
                      metrics such as response rates, conversion rates, and ROI.
                    </li>
                    <li className="text-secondary letter-space02 list-disc mb-4">
                      We implement A/B testing on various elements of your
                      campaigns to discover the most effective tactics.
                    </li>
                    <li className="text-secondary letter-space02 list-disc mb-4">
                      We refine and adjust campaigns based on performance data
                      to enhance outcomes and meet your business goals.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <MeetingScheduleQualifiedMeetings />
        <MeetingSchedulingWhyChooseRevli />
        <section className="pt-10 pb-[3.875rem]">
          <div className="container mx-auto">
            <div className="lg:w-8/12 mx-auto pb-16 border-b border-black border-opacity-20">
              <h2 className="text-3xl lg:text-[2.75rem] font-extrabold text-center lg-line-height3">
                Let’s talk sales
              </h2>
              <p className="secondary-font text-lg lg:text-xl font-bold text-center mt-4">
                Get in touch for a free assessment with one of our B2B growth
                strategists.
              </p>
              <div className="lg:w-8/12 mx-auto flex flex-col mt-8">
                <div>
                  <h3 className="text-lg lg:text-xl font-bold">
                    On the 30 minute call we will:
                  </h3>
                </div>
                <ul className="ml-4 mt-4">
                  <li className="text-lg lg:text-xl list-disc letter-space02">
                    Get to know you and your business
                  </li>
                  <li className="text-lg lg:text-xl list-disc letter-space02">
                    Review your goals and targets
                  </li>
                  <li className="text-lg lg:text-xl list-disc letter-space02">
                    Present our software/services and how they might fit
                  </li>
                  <li className="text-lg lg:text-xl list-disc letter-space02">
                    Establish next step
                  </li>
                </ul>
                <button
                  className="btn btn--primary btn--h60 text-lg lg:text-[1.438rem] mt-8 lg:mt-16"
                  onClick={() => {
                    window.open("https://calendly.com/revli", "_blank");
                  }}
                >
                  Schedule a meeting
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-[3.875rem] pb-[7rem]">
          <FAQ topic={CHOICES.FAQTopic.MEETING_SCHEDULING} />
        </section>
        <Testimonials />
        <GetStarted />
        <Footer />
      </section>
    </main>
  );
};

export default MeetingSchedule;
