import React from "react";
import leadGeneration from "../assets/images/cost-effective-lead-generation.svg";
import audienceIdentification from "../assets/images/audience-identification.svg";
import messagingAndMultichannel from "../assets/images/messaging-and-multichannel.svg";
import abTesting from "../assets/images/ab-testing.svg";
import campaignMgmntMeetingSchedule from "../assets/images/campaign-mgmnt-meeting-schedule.svg";
import actionableInsights from "../assets/images/actionable-insights.svg";

const MeetingScheduleQualifiedMeetings = () => {
  const meetingSchedulingMeetingData = [
    {
      picture: leadGeneration,
      title: "Cost-Effective Lead Generation Strategies",
      desc: "Every dollar counts in your startup journey. Our services are designed to deliver the highest return on your investment, focusing on cost-effective strategies that convert prospects to demos without draining resources.",
    },
    {
      picture: audienceIdentification,
      title: "Market Analysis and Audience Identification",
      desc: "Discover the heart of your market with our analytical precision and strategic planning. We delve into your sector's dynamics to unveil opportunities and tailor a tactical plan that speaks directly to your prospects' needs.",
    },
    {
      picture: messagingAndMultichannel,
      title: "Personalized Messaging and Multichannel Outreach",
      desc: "Segmentation meets sophistication with our AI-driven outreach, crafting messages that resonate on a personal level. Experience higher engagement rates as we fine-tune conversations to align with your prospects' evolving interests.",
    },
    {
      picture: abTesting,
      title: "A/B Testing and Continuous Optimization",
      desc: "Elevate your campaign's efficiency with ongoing A/B testing guided by our experts. We dissect performance data to refine strategies continuously, driving better results and ensuring your message evolves with your market.",
    },
    {
      picture: campaignMgmntMeetingSchedule,
      title: "Campaign Management and Meeting Scheduling",
      desc: "We navigate the complexities of campaign execution so you can focus on building relationships. From initial touchpoints to sustained engagement, our team is the engine behind your dynamic sales process.",
    },
    {
      picture: actionableInsights,
      title: "Detailed Reporting and Actionable Insights",
      desc: "Arm your strategy with actionable insights from our in-depth analytics. We monitor key performance indicators, providing you with real-time feedback and strategic recommendations to keep your pipeline robust and your strategies sharp.",
    },
  ];
  return (
    <section className="pt-[3.875rem] relative">
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl lg:text-[2.875rem] font-extrabold line-height3 text-center">
          Revitalize your pipeline with <br className="hidden lg:block"></br>
          qualified meetings
        </h2>
      </div>
      <div className="w-11/12 mx-auto mt-10 lg:mt-[7rem] px-6">
        <div className="flex flex-wrap justify-center flex-col lg:flex-row -mx-6">
          {meetingSchedulingMeetingData.map((item, index) => (
            <div key={index} className="lg:w-5/12 px-6 mb-20 last:mb-0">
              <div className="flex flex-col">
                <img
                  src={item.picture}
                  alt=""
                  className="w-full xl:h-[19.563rem]"
                />
                <div className="mt-4 lg:mt-8">
                  <h3 className="text-xl md:text-2xl lg:text-3xl font-bold lg:pr-5">
                    {item.title}
                  </h3>
                  <p className="text-secondary lg:text-lg letter-space02 mt-4">
                    {item.desc}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MeetingScheduleQualifiedMeetings;
