import React, { useRef } from "react";
// import Header from "../components/Header";
import coldEmailSectionPic from "../assets/images/cold-section-pic.png";
import emailScorePic from "../assets/images/email-score-pic.png";
import HomeSolutions from "../components/HomeSolutions";
import FAQ from "../components/FAQ";
import LatestArticles from "../components/LatestArticles";
import Testimonials from "../components/Testimonials";
import GetStarted from "../components/GetStarted";
import Footer from "../components/Footer";
import HowRevliWork from "../components/HowRevliWork";
import CHOICES from "../CHOICES";
import HeaderNew from "../components/HeaderNew";
import EmailWarmupHero from "../components/EmailWarmupHero";
import EmailWarmupFeatures from "../components/EmailWarmupFeatures";
const EmailWarmup = () => {
  const linksRef = useRef(null);
  return (
    <main className="">
      <section>
        {/* <Header myref={linksRef} /> */}
        <HeaderNew />
        <EmailWarmupHero />
        <section className="section-cold-email">
          <div className="main-container">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 mb-6 lg:mb-0">
                <h2 className="heading-level2 leading-tight">
                  Double your open rates<p>and stop landing in spam.</p>
                </h2>
              </div>
              <div className="w-full lg:w-6/12 xl:w-4/12 xl:pl-2">
                <p className="secondary-font text-26 font-bold pb-4">
                  Reach 2X leads and customers and close more deals
                </p>
                <span
                  className="block w-2/12"
                  style={{ borderBottom: "3px solid #4B9DEA" }}
                ></span>
                <div className="md:text-lg lg:text-xl mt-5">
                  <p className="font-bold italic">
                    48% of all cold emails land in spam.
                  </p>
                  <p className="text-secondary">
                    Revli makes sure that yours don't.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-cold-email2">
          <div className="main-container">
            <div className="flex items-center flex-wrap">
              <div className="w-full lg:w-7/12 lg:-ml-24 mb-6 lg:mb-0">
                <img
                  src={coldEmailSectionPic}
                  alt=""
                  className="section-cold-email2__pic"
                />
              </div>
              <div className="w-full lg:w-5/12 lg:-mt-20">
                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold line-height3 md:pr-3">
                  Send cold emails and follow-ups that hit your prospect's inbox
                  every time
                </h2>
                <p className="text-base text-secondary md:text-lg lg:text-xl mt-6 letter-space02">
                  Stop missing prospects due to spam. Revli ensures that your
                  cold emails are delivered to your lead's primary inbox,
                  maximizing open rates, reply rates and meetings booked.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-email-score">
          <div className="main-container">
            <div className="flex items-center flex-wrap">
              <div className="section-email-score__content-left">
                <h3 className="text-xl md:text-2xl lg:text-4xl font-bold line-height3 md:pr-10">
                  Turn insights into actions that improve your cold email
                  outreach
                </h3>
                <p className="text-base text-secondary md:text-lg lg:text-xl mt-4 md:mt-8 letter-space02">
                  Get actionable insights to build and maintain your inbox
                  health. Revli monitors your domain reputation, sender score,
                  email deliverability and blacklists with real-time alerts and
                  recommendations.
                </p>
              </div>
              <div className="section-email-score__hero-pic">
                <img
                  src={emailScorePic}
                  width={947}
                  height={647}
                  alt=""
                  className=""
                />
              </div>
            </div>
          </div>

          {/* <img
            src={emailScorePic}
            alt="email-score-pic"
            className="absolute top-10 right-0"
          /> */}
        </section>
        <EmailWarmupFeatures />
        <span id="how-it-works">
          <HowRevliWork myref={linksRef} />
        </span>
        <HomeSolutions />
        <section className="free-trail">
          <div className="main-container">
            <div className="free-trail__container">
              <h2 className="heading-level2 text-center">
                7-day free trial <p>$15 per inbox, per month</p>
              </h2>
              <button
                onClick={() =>
                  (window.location.href = "https://app.revli.com/register")
                }
                className="btn btn--primary text-sm font-medium md:text-2xl py-3 px-10 mt-4 md:mt-8"
              >
                Start 7-Day Free Trial
              </button>
              <p className="text-lg font-medium mt-4 letter-space02">
                No credit card required.
              </p>
            </div>
          </div>
        </section>
        <FAQ topic={CHOICES.FAQTopic.EMAIL_WARMUP} />
        <section className="py-[3.875rem]">
          <LatestArticles
            article={"Popular Articles"}
            subhead={""}
            articleKind={CHOICES.ArticleKind.FEATURE_ARTICLES}
          />
        </section>
        <Testimonials />
        <GetStarted />
        <Footer />
      </section>
    </main>
  );
};

export default EmailWarmup;
