import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import AboutUs from "./pages/AboutUs";
import Blogs from "./pages/Blogs";
import BlogsDetails from "./pages/BlogsDetails";
import ContactUs from "./pages/ContactUs";
import EmailSpamTest from "./pages/EmailSpamTest";
import EmailWarmup from "./pages/EmailWarmup";
import FAQ from "./pages/FAQ";
import FundingInsights from "./pages/FundingInsights";
import HomePage from "./pages/HomePage";
import ListenRoute from "./components/ListenRoute";
import MeetingSchedule from "./pages/MeetingSchedule";
import NotFound404Page from "./components/NotFound404Page";
import Pricing from "./pages/Pricing";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import React from "react";
import ScrollToTop from "./components/scrollTop";
import Terms from "./pages/Terms";
import { ToastContainer } from "react-toastify";
// import HowRevliWork from "./components/HowRevliWork";
import { boot as bootIntercom } from "./components/Intercom";

function App() {

  // loadIntercom()
  // shutdown();
  bootIntercom();
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <ListenRoute />
        <ToastContainer limit={1} />

        <Routes>
          {/* <HomePage /> */}
          <Route path="/" element={<HomePage />} />
          <Route index element={<HomePage />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="email-test" element={<EmailSpamTest />} />
          <Route path="email-warmup" element={<EmailWarmup />} />
          {/* <Route path="how-it-works" element={<HowRevliWork/>}/> */}
          <Route path="pricing" element={<Pricing />} />
          <Route path="blog" element={<Blogs />} />
          {/* <Route path="blogs" element={<Blogs />} /> */}
          {/* <Route path="blogs-details" element={<BlogsDetails />} /> */}
          <Route path="blog/:id" element={<BlogsDetails />} />
          <Route path="404-not-found" element={<NotFound404Page />} />
          <Route path="funding-insights" element={<FundingInsights />} />
          <Route path="meeting-scheduling" element={<MeetingSchedule />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="*" element={<Navigate to="404-not-found" replace />} />
          {/* <PrivacyPolicy /> */}
          {/* <Terms /> */}
          {/* <ContactUs /> */}
          {/* <FAQ /> */}
          {/* <EmailSpamTest /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
