import React from "react";
import notFoundImage from "../assets/images/404.png";
import { useNavigate } from "react-router-dom";

const NotFound404Page = () => {
  const navigate = useNavigate();

  return (
    <section className="flex-col absolute flex justify-center items-center w-full">
      <h2 className="font-extrabold text-32 mt-8">Page Not Found</h2>
      <img src={notFoundImage} alt="not found" className="py-6 w-5/12" />
      <button
        onClick={() => navigate("/")}
        className="btn btn--primary btn--sm"
      >
        Go Home
      </button>
    </section>
  );
};

export default NotFound404Page;
