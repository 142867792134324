import React from "react";
// import Header from "../components/Header";
import HeroHome from "../components/HeroHome";
// import coldEmailSectionPic from "../assets/images/cold-section-pic.png";
// import emailScorePic from "../assets/images/email-score-pic.png";
import HomeFeatures from "../components/HomeFeatures";
// import HomeSolutions from "../components/HomeSolutions";
import FAQ from "../components/FAQ";
import LatestArticles from "../components/LatestArticles";
import Testimonials from "../components/Testimonials";
import GetStarted from "../components/GetStarted";
import Footer from "../components/Footer";
// import HowRevliWork from "../components/HowRevliWork";
import emailWarmup from "../assets/images/email-warmup.webp";
import billingInvoice from "../assets/images/billing-report-invoice.webp";
import spamTestPerformance from "../assets/images/spam-test-performance.svg";
import meetingScheduling from "../assets/images/meeting-scheduling.svg";
import { useNavigate } from "react-router-dom";

import CHOICES from "../CHOICES";
import HeaderNew from "../components/HeaderNew";
const HomePage = () => {
  const navigate = useNavigate();
  // const linksRef = useRef(null);
  return (
    <main className="">
      <section>
        {/* <Header myref={linksRef} /> */}
        <HeaderNew />
        <HeroHome />
        <section className="bg-white pt-16 lg:pt-20 pb-[3.875rem] lg:pb-[7.75rem]">
          <div className="container mx-auto flex justify-between flex-col lg:flex-row">
            <div className="lg:w-6/12">
              <h2 className="text-2xl md:text-4xl lg:text-[2.75rem] font-bold lg-line-height3">
                Innovative solutions to fuel your{" "}
                <span className="text-primary-brand-dark">business growth</span>
              </h2>
            </div>
            <div className="lg:w-5/12 mt-8 lg:mt-0">
              <h3 className="text-2xl lg:text-[1.625rem] font-bold lg-line-height3 letter-space02">
                Revli helps sales and marketing teams increase their win rates
                by 26%
              </h3>
              <hr className="w-[4.563rem] border-b-2 border-primary-brand mt-3"></hr>
              <p className="text-secondary text-xl mt-4 pr-10 letter-space02">
                Create and close more pipeline with prospect data and outreach
                solutions that drive results.
              </p>
            </div>
          </div>
        </section>
        <section className="pt-[3.875rem] lg:pt-[7.5rem] pb-16 lg:pb-20 section-bg-gradient">
          <div className="container mx-auto">
            <h2 className="secondary-font text-2xl md:text-4xl lg:text-[2.75rem] font-extra-bold text-center">
              Our Products
            </h2>
            <div className="flex justify-between flex-col lg:flex-row mt-10 lg:mt-[7rem]">
              <img
                src={emailWarmup}
                alt="email-warmup"
                className="max-w-full lg:max-w-[36.75rem] lg:max-h-[27.75rem]"
              />
              <div className="lg:w-6/12 lg:pl-6 xl:pl-20 xl:pr-[5.313rem] mt-8 lg:mt-0">
                <h4 className="secondary-font text-blue1 text-lg lg:text-xl font-bold">
                  Email Warmup
                </h4>
                <div className="mt-6">
                  <h3 className="secondary-font text-xl md:text-2xl lg:text-4xl font-bold">
                    Double your open rates and stop landing in spam
                  </h3>
                  <p className="text-secondary text-lg lg:text-xl letter-space02 mt-3">
                    Stop missing prospects due to spam. Ensure that your cold
                    emails are delivered to your lead's primary inbox,
                    maximizing open rates, replies and meetings booked.
                  </p>

                  <div
                    className="flex items-center text-primary-brand-dark text-lg lg:text-xl font-bold letter-space02 mt-6 hover:text-primary-brand transition-colors cursor-pointer"
                    onClick={() => navigate("/email-warmup")}
                  >
                    Learn more about Email Warmup &gt;
                  </div>
                </div>
                <div className="inline-flex flex-col items-center mt-8 lg:mt-12">
                  <button
                    className="btn btn--primary btn--h60 px-8 w-full md:w-[20.25rem] lg:text-[1.375rem] py-2"
                    onClick={() =>
                      (window.location.href = "https://app.revli.com/register")
                    }
                  >
                    Start 7-Day Free Trial
                  </button>
                  <p className="secondary-font font-extra-bold text-sm lg:text-base mt-3">
                    No credit card required.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-[3.875rem] lg:pt-[7.5rem] pb-16 lg:pb-20 section-bg-gradient">
          <div className="container mx-auto">
            <div className="flex justify-between flex-col lg:flex-row">
              <div className="lg:w-6/12 lg:pr-5 xl:pr-12">
                <h4 className="secondary-font text-blue1 text-lg lg:text-xl font-bold">
                  Funding Insights
                </h4>
                <div className="mt-6">
                  <h3 className="secondary-font text-xl md:text-2xl lg:text-4xl font-bold">
                    Find fast-growing startups with recent funding
                  </h3>
                  <p className="text-secondary text-lg lg:text-xl letter-space02 mt-3">
                    Access thousands of founders each month at recently funded
                    startups. Target growth-ready businesses when they are
                    actively looking for new solutions and services.
                  </p>
                  <div
                    className="flex items-center text-primary-brand-dark text-lg lg:text-xl font-bold letter-space02 mt-6 hover:text-primary-brand transition-colors cursor-pointer"
                    onClick={() => navigate("/funding-insights")}
                  >
                    Learn more about Funding Insights &gt;
                  </div>
                </div>
                <div className="inline-flex flex-col items-center mt-8 lg:mt-12">
                  <button
                    className="btn btn--primary btn--h60 px-8 w-full md:w-[20.25rem] text-lg lg:text-[1.375rem] py-2"
                    onClick={() =>
                      (window.location.href = "https://app.revli.com/register")
                    }
                  >
                    Get Your First Report Free
                  </button>
                  <p className="secondary-font font-extra-bold text-sm lg:text-base mt-3">
                    No credit card required.
                  </p>
                </div>
              </div>
              <img
                src={billingInvoice}
                alt="billing-invoice"
                className="max-h-full lg:w-[46.25rem] lg:h-[29.625rem] mb-8 lg:mb-0 -order-[1] lg:order-1"
              />
            </div>
          </div>
        </section>
        <section className="pt-[3.875rem] lg:pt-[7.5rem] pb-16 lg:pb-20 section-bg-gradient">
          <div className="container mx-auto">
            <div className="flex justify-between flex-col lg:flex-row">
              <img
                src={spamTestPerformance}
                alt="email-warmup"
                className="max-h-full lg:max-w-[36.938rem] lg:max-h-[27.906rem]"
              />
              <div className="lg:w-6/12 lg:pl-6 xl:pl-20 xl:pr-[4.375rem] mt-8 lg:mt-0">
                <h4 className="secondary-font text-blue1 text-lg lg:text-xl font-bold">
                  Email Spam Test
                </h4>
                <div className="mt-6">
                  <h3 className="secondary-font text-xl md:text-2xl lg:text-4xl font-bold">
                    Boost the success of your email campaigns
                  </h3>
                  <p className="text-secondary text-lg lg:text-xl letter-space02 mt-3">
                    Test your emails against advanced spam filters. Receive tips
                    and recommendations to improve your outreach and achieve
                    perfect deliverability.
                  </p>
                  <div
                    className="flex items-center text-primary-brand-dark text-lg lg:text-xl font-bold letter-space02 mt-6 hover:text-primary-brand transition-colors cursor-pointer"
                    onClick={() => navigate("/email-test")}
                  >
                    Learn more about Email Spam Test &gt;
                  </div>
                </div>
                <div className="inline-flex flex-col items-center mt-8 lg:mt-12">
                  <button
                    className="btn btn--primary btn--h60 px-8 w-full md:w-[20.25rem] text-lg lg:text-[1.375rem] py-2"
                    onClick={() => navigate("/email-test")}
                  >
                    Try it free
                  </button>
                  <p className="secondary-font font-extra-bold text-sm lg:text-base mt-3">
                    100% free. No sign-up required.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-[3.875rem] lg:pt-[7.75rem] pb-[3.875rem] bg-white">
          <div className="container mx-auto">
            <h2 className="text-2xl md:text-4xl lg:text-[2.75rem] font-extrabold text-center">
              Our Services
            </h2>
            <div className="flex items-center justify-between flex-col lg:flex-row pt-10 lg:pt-[6rem]">
              <div className="lg:w-5/12 lg:pr-10 xl:pr-20">
                <h4 className="secondary-font text-blue1 text-lg lg:text-xl font-bold">
                  Meeting Scheduling
                </h4>
                <div className="mt-4 lg:mt-6">
                  <h3 className="text-xl md:text-2xl lg:text-[2.125rem] font-bold line-height3">
                    Stop prospecting <br className="hidden lg:block"></br>and
                    start closing
                  </h3>
                  <p className="text-secondary text-lg lg:text-xl letter-space02 mt-3">
                    We generate, you convert. High-quality demos and meetings
                    delivered.
                  </p>
                  <div
                    className="flex items-center text-primary-brand-dark text-lg lg:text-xl font-bold letter-space02 mt-6 hover:text-primary-brand transition-colors cursor-pointer"
                    onClick={() => navigate("/meeting-scheduling")}
                  >
                    Learn more about Meeting Scheduling &gt;
                  </div>
                </div>
                <div className="inline-flex flex-col items-center mt-8 lg:mt-12">
                  <button
                    className="btn btn--primary btn--h60 px-8 w-full md:w-[20.25rem] text-lg lg:text-[1.375rem] py-2"
                    onClick={() => {
                      window.open("https://calendly.com/revli", "_blank");
                    }}
                  >
                    Schedule a Call
                  </button>
                </div>
              </div>
              <img
                src={meetingScheduling}
                alt="meeting-scheduling"
                className="mb-8 lg:mb-0 -order-[1] lg:order-1"
              />
            </div>
          </div>
        </section>
        <HomeFeatures />
        <section className="py-[3.875rem]">
          <LatestArticles
            article={"Latest Articles"}
            subhead={""}
            articleKind={CHOICES.ArticleKind.FEATURE_ARTICLES}
          />
        </section>
        <section className="pt-[3.875rem] pb-[3.875rem] lg:pb-[7rem]">
          <FAQ topic={CHOICES.FAQTopic.HOME_PAGE} />
        </section>
        <Testimonials />
        <GetStarted />
        {/* <section className="section-cold-email">
          <div className="main-container">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 mb-6 lg:mb-0">
                <h2 className="heading-level2 leading-tight">
                  Double your open rates<p>and stop landing in spam.</p>
                </h2>
              </div>
              <div className="w-full lg:w-6/12 xl:w-4/12 xl:pl-2">
                <p className="secondary-font text-26 font-bold pb-4">
                  Reach 2X leads and customers and close more deals
                </p>
                <span
                  className="block w-2/12"
                  style={{ borderBottom: "3px solid #4B9DEA" }}
                ></span>
                <div className="md:text-lg lg:text-xl mt-5">
                  <p className="font-bold italic">
                    48% of all cold emails land in spam.
                  </p>
                  <p className="text-secondary">
                    Revli makes sure that yours don't.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-cold-email2">
          <div className="main-container">
            <div className="flex items-center flex-wrap">
              <div className="w-full lg:w-7/12 lg:-ml-24 mb-6 lg:mb-0">
                <img
                  src={coldEmailSectionPic}
                  alt=""
                  className="section-cold-email2__pic"
                />
              </div>
              <div className="w-full lg:w-5/12 lg:-mt-20">
                <h2 className="text-xl md:text-2xl lg:text-4xl font-bold line-height3 md:pr-3">
                  Send cold emails and follow-ups that hit your prospect's inbox
                  every time
                </h2>
                <p className="text-base text-secondary md:text-lg lg:text-xl mt-6 letter-space02">
                  Stop missing prospects due to spam. Revli ensures that your
                  cold emails are delivered to your lead's primary inbox,
                  maximizing open rates, reply rates and meetings booked.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-email-score">
          <div className="main-container">
            <div className="flex items-center flex-wrap">
              <div className="section-email-score__content-left">
                <h3 className="text-xl md:text-2xl lg:text-4xl font-bold line-height3 md:pr-10">
                  Turn insights into actions that improve your cold email
                  outreach
                </h3>
                <p className="text-base text-secondary md:text-lg lg:text-xl mt-4 md:mt-8 letter-space02">
                  Get actionable insights to build and maintain your inbox
                  health. Revli monitors your domain reputation, sender score,
                  email deliverability and blacklists with real-time alerts and
                  recommendations.
                </p>
              </div>
              <div className="section-email-score__hero-pic">
                <img
                  src={emailScorePic}
                  width={947}
                  height={647}
                  alt=""
                  className=""
                />
              </div>
            </div>
          </div>
        </section> */}
        {/* <span id="how-it-works">
          <HowRevliWork myref={linksRef} />
        </span> */}
        {/* <HomeSolutions /> */}
        {/* <section className="free-trail">
          <div className="main-container">
            <div className="free-trail__container">
              <h2 className="heading-level2 text-center">
                7-day free trial <p>$15 per inbox, per month</p>
              </h2>
              <button
                onClick={() =>
                  (window.location.href = "https://app.revli.com/register")
                }
                className="btn btn--primary text-sm font-medium md:text-2xl py-3 px-10 mt-4 md:mt-8"
              >
                Start 7-Day Free Trial
              </button>
              <p className="text-lg font-medium mt-4 letter-space02">
                No credit card required.
              </p>
            </div>
          </div>
        </section> */}
        <Footer />
      </section>
    </main>
  );
};

export default HomePage;
