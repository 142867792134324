import React from "react";
import ellipseHeroPic from "../../assets/images/hero-bg-ellipse.svg";

const PricingHero = ({ setIsPriceCard, isPriceCard }) => {
  const toggleBtnLabel = [
    {
      id: 1,
      label: "Funding Insights",
    },
    {
      id: 2,
      label: "Email warmup",
    },
    {
      id: 3,
      label: "Meeting Scheduling",
    },
  ];
  return (
    <section
      className="hero-home hero-home--pricing"
      style={{ overflowX: "hidden" }}
    >
      <div className="relative container mx-auto z-[1]">
        <div className="flex flex-col text-center text-white w-full lg:w-9/12 mx-auto pt-[7rem] md:pt-[10rem] lg:pt-[13rem]">
          <h1 className="heading-level1">
            Crush your sales quota <br className="hidden lg:block"></br>with Revli
          </h1>
          <p className="text-lg lg:text-xl mt-4 letter-space02 opacity-70">
            Simple pricing for one-person startups to Fortune 500 enterprises.
          </p>
        </div>
        <div className="w-full lg:w-9/12 xl:w-7/12 px-10 mx-auto mt-[3.5rem]">
          <div className="w-full toggle-btn-group">
            {toggleBtnLabel.map((item, index) => (
              <>
                <input
                  type="radio"
                  id={item.label}
                  name="toggle-btn-group"
                  className="toggle-btn-group__input"
                  defaultChecked={index === 0}
                />
                <label
                  htmlFor={item.label}
                  key={index}
                  className="toggle-btn-group__label"
                  onClick={() => {
                    setIsPriceCard(item.label);
                  }}
                >
                  {item.label}
                </label>
              </>
            ))}
          </div>
        </div>
        {/* {toggleBtnLabel.map((item) => ( */}
        <>
          {isPriceCard === "Funding Insights" ? (
            <h4 className="text-white text-2xl font-extrabold text-center mt-16">
              Access 2500+ verified leads each month{" "}
              <br className="hidden lg:block"></br>from recently funded
              startups.
            </h4>
          ) : (
            <></>
          )}
        </>
        {/* ))} */}
      </div>
      <img
        src={ellipseHeroPic}
        alt="ellipse-hero-bg"
        className="absolute top-0 right-0 h-3/4"
      />
    </section>
  );
};

export default PricingHero;
