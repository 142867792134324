import { graphql } from "babel-plugin-relay/macro";

export const GetDynamicContentQuery = graphql`
  query GetDynamicContentQuery($kind: Int!) {
    getContent(kind: $kind) {
      kind
      context
      title
      id
    }
  }
`;
