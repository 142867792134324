import React from "react";
import iconClose from "../assets/images/icons/icon-close.svg";

const ModalConnectGoogleVideo = ({ open, onClose, videoSrc }) => {
  return (
    <section className={"modal modal--confirm-delete " + (open ? "show" : "")}>
      <div className="modal__container" style={{ width: "900px" }}>
        <div
          className="modal__header py-4 px-4"
          style={{ justifyContent: "flex-end" }}
        >
          <img
            src={iconClose}
            alt="icon-close"
            className="modal__close-icon"
            onClick={onClose}
          />
        </div>
        <div className="modal__body px-8 pb-8">
          <iframe
            width="100%"
            height="400"
            src={videoSrc}
            title="How to connect a Google Workspace account to Revli"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default ModalConnectGoogleVideo;
