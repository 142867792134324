import React from "react";
import funding from "../assets/images/icons/funding.svg";
import adaptive from "../assets/images/icons/adaptive.svg";
import expertGuidance from "../assets/images/icons/expert-guidance.svg";
import costEffective from "../assets/images/icons/cost-effective.svg";
import timeResources from "../assets/images/icons/time-resources.svg";
import noResults from "../assets/images/icons/no-results.svg";

const FIFeatures = () => {
  const fundingInsightsListData = [
    {
      img: costEffective,
      title: "You're struggling to find recently funded companies & contacts",
      subTitle:
        "Get weekly lists of recently funded companies and contacts to streamline your lead generation process.",
    },
    {
      img: timeResources,
      title: "You’re wasting hours each week on research & prospecting",
      subTitle:
        "Save time and money with access to verified emails and contact data, letting you focus on engaging leads.",
    },
    {
      img: expertGuidance,
      title: "You're missing out on time sensitive deal opportunities",
      subTitle:
        "Gain an edge with real-time reports on recent funding, ensuring you never miss the perfect timing for outreach.",
    },
    {
      img: funding,
      title: "You're spending too much on expensive tools & subscriptions",
      subTitle:
        "Cut costs without cutting corners. Access funded startup data at a fraction of the cost of other databases.",
    },
    {
      img: noResults,
      title: "You're reaching out to leads with no budget or interest",
      subTitle:
        "Focus your efforts on prospects with buying power and an immediate need to grow and invest.",
    },
    {
      img: adaptive,
      title: "Your looking for faster client and revenue growth",
      subTitle:
        "Unlock rapid growth opportunities with recently funded startups who are ready to invest in new solutions and services.",
    },
  ];
  return (
    <section className="pt-[3.875rem] pb-[6.25rem] relative">
      <div className="container mx-auto">
        <h2 className="text-2xl md:text-3xl lg:text-[2.875rem] font-extrabold line-height3 text-center">
          The challenges slowing down <br className="hidden lg:block"></br> your
          revenue growth
        </h2>
        <div className="mt-10 lg:mt-[7rem] relative">
          <ul className="flex lg:flex-wrap -mx-3 overflow-x-auto pt-8 lg:pt-0 lg-overflow-x-initial scrollbar-none overflow-x-behaviour-contain">
            {fundingInsightsListData.map((item, index) => (
              <li
                key={index}
                className="w-11/12 lg:w-4/12 flex-shrink-0 lg-flex-shrink-initial px-3 mb-6"
              >
                <div className="h-full card-box">
                  <figure className="fig-wrapper">
                    <img src={item.img} alt="icon-telegram" />
                  </figure>
                  <div className="mt-8 lg:mt-[3.375rem] flex flex-col">
                    <h5 className="text-lg lg:text-xl font-bold">
                      {item.title}
                    </h5>
                    <p className="secondary-font text-secondary lg:text-lg mt-[0.625rem] letter-space02">
                      {item.subTitle}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FIFeatures;
