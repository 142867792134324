import React from "react";
import iconTelegram from "../assets/images/icons/icon-telegram.svg";
import iconConversation from "../assets/images/icons/icon-conversation.svg";
import iconCloudBackup from "../assets/images/icons/icon-cloudbackup.svg";
import iconSecurity from "../assets/images/icons/icon-security.svg";
import iconInbox from "../assets/images/icons/icon-inbox.svg";
import iconRocket from "../assets/images/icons/icon-rocket.svg";
const featuresListData = [
  {
    img: iconTelegram,
    title: "Increase your sender reputation and email deliverability",
    subTitle:
      "Revli's inbox interactions send positive signals to email servers and their spam filters, teaching them to view your cold emails as safe and credible.",
  },
  {
    img: iconConversation,
    title: "Avoid spam folders and blacklists",
    subTitle:
      "Over time, all cold email inboxes suffer from deliverability issues. Revli helps to reverse this process by keeping you out of spam folders and off blacklists.",
  },
  {
    img: iconCloudBackup,
    title: "Improve open rates, reply rates, clicks and conversions",
    subTitle:
      "Higher delivery rates mean more eyes on your emails. Revli works to maximize open rates, reply rates and meetings booked.",
  },
  {
    img: iconSecurity,
    title: "Certify your inbox as a safe and trusted sender",
    subTitle:
      "Revli monitors your domain reputation and sender score with real-time alerts and recommendations to build and maintain your inbox health.",
  },
  {
    img: iconInbox,
    title: "Keep your deliverability rates and inbox placement high",
    subTitle:
      "Revli runs in the background and on auto-pilot, ensuring your deliverability rates remain high and your cold emails always stay out of spam.",
  },
  {
    img: iconRocket,
    title: "Skyrocket the results of your cold email campaigns",
    subTitle:
      "Spend less time prospecting and more time closing. Rest assured that your cold emails will stop landing in spam and start delivering results.",
  },
];

const EmailWarmupFeatures = () => {
  return (
    <section className="features-section">
      <div className="main-container">
        <h2 className="w-full xl:w-7/12 heading-level2 text-center md:mx-auto">
          Get out of the spam folder and into the spotlight
        </h2>
      </div>
      <div className="scroll-x-wrapper">
        <div className="main-container">
          <ul className="features-section__list-wrapper mt-8 md:mt-16">
            {featuresListData.map((item, index) => (
              <li key={index} className="features-section__list">
                <div className="features-section__list__container">
                  <figure className="features-section__list__icon-wrapper">
                    <img src={item.img} alt="icon-telegram" />
                  </figure>
                  <h5 className="features-section__list__title">
                    {item.title}
                  </h5>
                  <p className="features-section__list__desc">
                    {item.subTitle}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default EmailWarmupFeatures;
