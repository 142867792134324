import React, { useEffect, useState } from "react";
import connectInbox from "../assets/images/connect-inbox.png";
import automaticEmails from "../assets/images/automatic-emails.png";
import inboxProviders from "../assets/images/inbox-providers.png";
import mailboxScore from "../assets/images/mailbox-score.png";


const HowRevliWork = ({myref}) => {
  const [width, setWindowWidth] = useState(0)
  useEffect(() => { 

    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => 
      window.removeEventListener("resize",updateDimensions);
   }, [])
   const updateDimensions = () => {
     const width = window.innerWidth
     setWindowWidth(width)
   }
  return (
    <section className="how-works"  ref={myref}>
     <div className="main-container">
      <h2 className="heading-level2 text-center" >How does Revli work?</h2>
      <ul className="how-works__list-wrapper">
        <li className="how-works__list">
          <img src={connectInbox} alt="connect-box" />
         
          {width < 640 ?  <h3 className="how-works__title mt-6"> 1. Connect your inbox in seconds & start your warm-up</h3> :  <h3 className="how-works__title mt-6">  Connect your inbox in seconds & start your warm-up</h3>}
          <p className="how-works__desc mt-6">Connect Revli to your inbox in just a few clicks. Configure your warm-up settings and let Revli run on autopilot. Pause or adjust your warm-up schedule at any time.</p>
        </li>
        <li className="how-works__list">
          <img src={automaticEmails} alt="connect-box" />
          {width < 640 ? <h3 className="how-works__title mt-6"> 2. Automatic emails are sent and received</h3> : <h3 className="how-works__title mt-6">Automatic emails are sent and received</h3>}
          <p className="how-works__desc mt-6">Our AI will mimic human interactions to positively engage with your inbox. Automatic emails are sent and received via Revli's inbox network. Any warm-up emails that land in spam are removed, opened, marked as important, and responded to intelligently.</p>
        </li>
        <li className="how-works__list">
          <img src={inboxProviders} alt="connect-box" />
          {width < 640 ? <h3 className="how-works__title mt-6"> 3. Positive signals are sent to inbox providers and their spam filters</h3> : <h3 className="how-works__title mt-6">Positive signals are sent to inbox providers and their spam filters</h3>}
          <p className="how-works__desc mt-6">Revli's inbox interactions send positive signals to email servers and their spam filters, teaching them to build your sender reputation and view your cold emails as safe and credible.</p>
        </li>
        <li className="how-works__list">
          <img src={mailboxScore} alt="connect-box" />
          {width < 640 ? <h3 className="how-works__title -mt-12 md:-mt-10">4. Your sender reputation and email deliverability increases</h3> : <h3 className="how-works__title -mt-12 md:-mt-10">Your sender reputation and email deliverability increases</h3>}
          <p className="how-works__desc mt-6">Watch as your email deliverability, open rates, and reply rates soar. Spend less time prospecting and more time closing with cold email campaigns that deliver results.</p>
        </li>
      </ul>
     </div>
    </section>
  );
};

export default HowRevliWork;
