import React, { useState } from "react";
import Footer from "../components/Footer";
import HeaderNew from "../components/HeaderNew";
import fundingInsightsBanner from "../assets/images/funding-insights-banner.webp";
import marketIdentity from "../assets/images/market-identity.svg";
import marketFit from "../assets/images/market-fit.svg";
import craftingStrategy from "../assets/images/crafting-strategies.svg";
import userInsight from "../assets/images/user-insight.svg";
import adamProfilePic from "../assets/images/adam-goldstein.png";
import cedricProfilePic from "../assets/images/cedric-chen.png";
import subscribe from "../assets/images/subscribe.svg";
import leadReports from "../assets/images/lead-reports.svg";
import filtersAndExport from "../assets/images/filters-and-export.svg";
import engageProspects from "../assets/images/engage-prospects.svg";
import FIFeatures from "../components/FIFeatures";
import FIWeeklyReport from "../components/FIWeeklyReport";
import FIWhyChooseRevli from "../components/FIWhyChooseRevli";
import FAQ from "../components/FAQ";
import CHOICES from "../CHOICES";
import Testimonials from "../components/Testimonials";
import GetStarted from "../components/GetStarted";
import polygonBgBanner from "../assets/images/polygon-bg-banner2.svg";
import ModalConnectGoogleVideo from "../components/ModalConnectGoogleVideo";

const FundingInsights = () => {
  const [showYTVideo, setShowYTVideo] = useState(false);
  const leadCriteriaData = [
    "Industry & Keywords",
    "Contact Location",
    "Company Location",
    "Company Size",
    "Company Revenue",
    "Job titles",
    "Buying Intent",
    "Technographics",
    "And more!",
  ];
  const preciseaData = [
    "Verified Emails",
    "Personal LinkedIn Profiles",
    "Company Website",
    "Company Social Profiles",
    "Company Phone Numbers",
    "Company Tech Stack",
    "Number Of Employees",
    "Company & Contact Location",
    "And more!",
  ];
  return (
    <main className="">
      <section className="funding-insights">
        <HeaderNew />
        <section className="relative pt-[7rem] sm:pt-[10rem] lg:pt-[13rem] pb-8 lg:pb-0 z-[1] bg-hero">
          <div className="container mx-auto flex flex-col lg:flex-row">
            <div className="lg:w-6/12 pr-5 xl:pr-10">
              <h1 className="text-white text-4xl md:text-5xl lg:text-6xl xl:text-[4rem] font-bold lg-line-height3">
                Find startups with <br className="hidden lg:block"></br>
                recent funding
              </h1>
              <p className="text-white text-base md:text-xl mt-4 letter-space02 opacity-70">
                Connect with thousands of key decision-makers each month at
                newly funded startups in search of new solutions and services.
              </p>
              <div className="flex flex-col md:flex-row mt-10">
                <button
                  className="btn btn--primary btn--h52 xl:text-xl font-bold py-2 px-5 md:w-7/12"
                  onClick={() =>
                    (window.location.href = "https://app.revli.com/register")
                  }
                >
                  Get Your First Report Free
                </button>
                <button
                  className="btn btn--white btn--h52 xl:text-xl font-bold py-2 px-5 md:w-5/12 md:ml-4 mt-4 md:mt-0"
                  onClick={() => {
                    setShowYTVideo(true);
                  }}
                >
                  Watch Demo Video
                </button>
              </div>
            </div>
            <figure className="relative w-full lg:w-[45%] xl:w-[53%] lg:-top-[3rem] mt-4 lg:mt-0">
              <img
                src={fundingInsightsBanner}
                alt="funding-insights-banner"
                className="max-w-full lg:max-w-[65rem]"
              />
            </figure>
          </div>
          <img
            src={polygonBgBanner}
            alt="polygon-bg-banner"
            className="hidden lg:block absolute bottom-[0rem] -left-[10rem] right-0 mx-auto -z-[1]"
          />
        </section>
        <section className="bg-white pt-16 lg:pt-20 pb-[3.875rem] lg:pb-[7.75rem]">
          <div className="container mx-auto flex justify-between flex-col lg:flex-row">
            <div className="lg:w-5/12">
              <h2 className="secondary-font text-3xl lg:text-[2.75rem] font-bold lg-line-height3">
                Target recently funded startups that just
                <span className="text-primary-brand-dark">
                  {" "}
                  <br className="hidden lg:block"></br>raised millions
                </span>
              </h2>
            </div>
            <div className="lg:w-6/12 lg:pr-12 mt-8 lg:mt-0">
              <h3 className="text-2xl lg:text-[1.625rem] font-bold line-height3">
                Direct access to thousands of founders with big needs and big
                budgets.
              </h3>
              <hr className="w-[4.563rem] border-b-2 border-primary-brand mt-3"></hr>
              <p className="text-secondary text-lg lg:text-xl letter-space02 mt-4">
                Connect with 2500+ leads each month from recently funded
                startups who have fresh budgets and are looking for new
                solutions and services.
              </p>
            </div>
          </div>
        </section>
        <section className="section-bg-gradient pt-[3.875rem] lg:pt-[7.5rem] pb-16 lg:pb-20">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row justify-center">
              <img src={marketIdentity} alt="market-identity" />
              <div className="lg:w-6/12 mt-10 lg:pl-5 xl:pl-20">
                <h3 className="text-2xl lg:text-3xl xl:text-4xl font-bold">
                  Find high-quality leads in minutes, not hours
                </h3>
                <p className="text-secondary text-lg lg:text-xl letter-space02 mt-4">
                  Eliminate hours of manual research with our comprehensive
                  weekly funding reports. Get ready-to-use lead data in seconds
                  and focus on engaging prospects and closing deals.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-bg-gradient pt-[3.875rem] lg:pt-[7.5rem] pb-16 lg:pb-20">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row justify-center">
              <div className="lg:w-5/12 mt-10 lg:pr-5">
                <h3 className="text-2xl lg:text-3xl xl:text-4xl font-bold">
                  Seize valuable opportunities at the perfect moment
                </h3>
                <p className="text-secondary text-lg lg:text-xl letter-space02 mt-4">
                  Stay ahead of the competition by connecting with startups the
                  moment they secure funding. Be the first to reach out to
                  growth-ready businesses when they have fresh budgets and are
                  actively looking for new solutions and services.
                </p>
              </div>
              <img
                src={marketFit}
                alt="market-fit"
                className="-order-[1] lg:order-1"
              />
            </div>
          </div>
        </section>
        <section className="section-bg-gradient pt-[3.875rem] lg:pt-[7.5rem] pb-16 lg:pb-20">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row justify-center">
              <img src={craftingStrategy} alt="crafting-strategy" />
              <div className="lg:w-6/12 mt-10 lg:pl-20">
                <h3 className="text-2xl lg:text-3xl xl:text-4xl font-bold">
                  Filter and connect with your ideal target audience
                </h3>
                <p className="text-secondary text-lg lg:text-xl letter-space02 mt-4">
                  Filter and personalize your campaigns with 20+ data points
                  including job title, industry, company size, location and
                  recent funding amount. Only export the relevant company and
                  contact information that you need.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-[3.875rem] lg:pt-[7.5rem] pb-16 lg:pb-20 bg-white">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row justify-center">
              <div className="lg:w-5/12 mt-10 lg:pr-5">
                <h3 className="text-2xl lg:text-3xl xl:text-4xl font-bold">
                  Gain a competitive edge with exclusive data
                </h3>
                <p className="text-secondary text-lg lg:text-xl letter-space02 mt-4">
                  Leverage premium lists crafted through countless hours of
                  manual research. We carefully build and verify each report by
                  hand, combining exclusive data with industry expertise to
                  deliver high-quality leads you won't find elsewhere.
                </p>
              </div>
              <img
                src={userInsight}
                alt="market-fit"
                className="-order-[1] lg:order-1"
              />
            </div>
          </div>
        </section>
        <section className="py-[3.875rem]">
          <div className="container mx-auto">
            <div className="flex items-center justify-center flex-col lg:flex-row lg:-mx-6">
              <div className="lg:w-6/12 lg:px-6">
                <div className="flex items-center flex-col lg:flex-row lg:px-6">
                  <figure className="flex items-center justify-center flex-shrink-0 w-[6.5rem] h-[6.5rem] rounded-full">
                    <img
                      src={adamProfilePic}
                      alt="adam-profile"
                      className="w-full h-full object-cover rounded-full"
                    />
                  </figure>
                  <div className="flex flex-col ml-6 mt-5 lg:mt-0">
                    <p className="text-[#22343F] font-bold italic letter-space02">
                      Revli's recent funding reports are essential for anyone
                      targeting rapid-growth markets. It's the tool we trust to
                      stay ahead in a competitive landscape.
                    </p>
                    <div className="mt-3">
                      <h3 className="text-lg lg:text-xl font-bold">
                        Adam Goldstein
                      </h3>
                      <p className="text-sm lg:text-base secondary-font text-[#4C5D67] mt-2">
                        Business Development Executive @ Truspace
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:w-6/12 lg:px-6 mt-10 lg:mt-0">
                <div className="flex items-center flex-col lg:flex-row lg:px-6">
                  <figure className="flex items-center justify-center flex-shrink-0 w-[6.5rem] h-[6.5rem] rounded-full">
                    <img
                      src={cedricProfilePic}
                      alt="cedric-profile"
                      className="w-full h-full object-cover rounded-full"
                    />
                  </figure>
                  <div className="flex flex-col ml-6 mt-5 lg:mt-0">
                    <p className="text-[#22343F] font-bold italic letter-space02">
                      Revli consistently delivers the best ROI on marketing
                      spend. It's a huge time saver for prospecting and great
                      resource for making informed and strategic decisions.
                    </p>
                    <div className="mt-3">
                      <h3 className="text-lg lg:text-xl font-bold">
                        Cedric Chen
                      </h3>
                      <p className="text-sm lg:text-base secondary-font text-[#4C5D67] mt-2">
                        Director of Search @ Clue Digital
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FIFeatures />
        <section className="py-[3.875rem] lg:py-[7rem] bg-section2">
          <div className="container mx-auto">
            <h2 className="text-3xl lg:text-[2.875rem] font-extrabold line-height3 text-center">
              How it works
            </h2>
            <div className="stepper-wrapper mt-16 lg:mt-20">
              <div className="stepper__list flex items-center justify-between flex-col lg:flex-row pb-[6.5rem]">
                <div className="lg:w-5/12 lg:pr-8">
                  <h3 className="text-xl lg:text-[2rem] font-bold lg-line-height3">
                    Sign up for Revli
                  </h3>
                  <p className="text-secondary lg:text-lg letter-space02 mt-4">
                    Start your free trial to access our latest weekly report of
                    recently funded startups. Sign up in less than a minute with
                    no credit card required.
                  </p>
                </div>
                <div className="lg:w-5/12 lg:pl-8 mb-8 lg:mb-0 -order-[1] lg:order-1">
                  <img src={subscribe} alt="subscribe" />
                </div>
              </div>
              <div className="stepper__list flex items-center justify-between flex-col lg:flex-row pb-[6.5rem]">
                <div className="lg:w-5/12 lg:pr-8">
                  <img src={leadReports} alt="lead-reports" />
                </div>
                <div className="lg:w-5/12 lg:pl-8 mt-8 lg:mt-0">
                  <h3 className="text-xl lg:text-[2rem] font-bold lg-line-height3">
                    Receive instant reports
                  </h3>
                  <p className="text-secondary lg:text-lg letter-space02 mt-4">
                    Subscribe for instant access to all leads from the previous
                    four weeks. Receive a new report each Monday featuring
                    handpicked leads from the most recently funded startups.
                  </p>
                </div>
              </div>
              <div className="stepper__list flex items-center justify-between flex-col lg:flex-row pb-[6.5rem]">
                <div className="lg:w-5/12 lg:pr-8">
                  <h3 className="text-xl lg:text-[2rem] font-bold lg-line-height3">
                    Filter and export your leads
                  </h3>
                  <p className="text-secondary lg:text-lg letter-space02 mt-4">
                    Filter your ideal leads based on job title, industry,
                    location, company size and recent funding amount. Utilize
                    20+ data points to refine your search and lead lists.
                  </p>
                </div>
                <div className="lg:w-5/12 lg:pl-8 mb-8 lg:mb-0 -order-[1] lg:order-1">
                  <img src={filtersAndExport} alt="filters-and-export" />
                </div>
              </div>
              <div className="stepper__list flex items-center justify-between flex-col lg:flex-row">
                <div className="lg:w-5/12 lg:pr-8">
                  <img src={engageProspects} alt="engage-your-prospects" />
                </div>
                <div className="lg:w-5/12 lg:pl-8 mt-8 lg:mt-0">
                  <h3 className="text-xl lg:text-[2rem] font-bold lg-line-height3">
                    Reach out and close deals
                  </h3>
                  <p className="text-secondary lg:text-lg letter-space02 mt-4">
                    Empower your sales and marketing teams with high-quality
                    leads who have budget and are seeking new solutions and
                    services. Start reaching out to connect and close deals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-16 pb-8 lg:pt-[7.75rem] lg:pb-[3.875rem]">
          <div className="container mx-auto">
            <div className="flex justify-center">
              <iframe
                className="w-[320px] h-[180px] sm:w-[520px] sm:h-[292px] md:w-[720px] md:h-[405px] lg:w-[980px] lg:h-[551px]"
                src="https://www.youtube.com/embed/moqfztNmP_I?si=DoyS9w9bWXXJmwkm"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </section>
        <FIWeeklyReport />
        <FIWhyChooseRevli />
        <section className="py-[3.875rem]">
          <div className="container mx-auto">
            <h2 className="text-3xl lg:text-[2.875rem] font-extrabold line-height3 text-center">
              Need a{" "}
              <span className="text-primary-brand-dark">custom built</span>{" "}
              list?
            </h2>
            <p className="text-xl lg:text-3xl font-extrabold text-center mt-4">
              3000 custom leads—up to three comprehensive reports for $499.00
            </p>
            <div className="flex -mx-4 mt-12 lg:mt-20 overflow-x-auto pt-8 pb-8 lg:pb-0 lg:pt-0 lg-overflow-x-initial scrollbar-none overflow-x-behaviour-contain">
              <div className="w-11/12 lg:w-6/12 px-4 flex-shrink-0 lg-flex-shrink-initial">
                <div className="h-full p-10 bg-white shadow-[0px_0px_120px_0px_#DEE5EC] rounded-[1.25rem]">
                  <h4 className="text-xl font-bold">
                    Customize your lead criteria
                  </h4>
                  <p className="text-secondary text-lg letter-space02 mt-2">
                    Specify your ideal customer profile with our in-depth
                    filters and contact data.
                  </p>
                  <ul className="flex flex-col mt-6 ml-6">
                    {leadCriteriaData.map((item, index) => (
                      <li
                        key={index}
                        className="text-[#293F4B] text-lg letter-space02 list-disc"
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="w-11/12 lg:w-6/12 px-4 flex-shrink-0 lg-flex-shrink-initial">
                <div className="h-full p-10 bg-white shadow-[0px_0px_120px_0px_#DEE5EC] rounded-[1.25rem]">
                  <h4 className="text-xl font-bold">
                    Supercharge your outreach with precise data
                  </h4>
                  <p className="text-secondary text-lg letter-space02 mt-2">
                    Receive a tailored list of leads with verified, high-quality
                    data to ensure you connect effectively
                  </p>
                  <ul className="flex flex-col mt-6 ml-6">
                    {preciseaData.map((item, index) => (
                      <li
                        key={index}
                        className="text-[#293F4B] text-lg letter-space02 list-disc"
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="md:w-6/12 xl:w-4/12 mx-auto flex items-center justify-center mt-10 lg:mt-20">
              <button
                className="btn btn--primary btn--h60 w-full text-lg lg:text-[1.438rem] px-8 py-2"
                onClick={() => {
                  window.open("https://calendly.com/revli", "_blank");
                }}
              >
                Schedule a meeting
              </button>
            </div>
          </div>
        </section>
        <section className="pt-[3.875rem] pb-[7rem]">
          <FAQ topic={CHOICES.FAQTopic.FUNDING_INSIGHTS} />
        </section>
        <Testimonials />
        <GetStarted />
        <Footer />
      </section>
      {showYTVideo && (
        <ModalConnectGoogleVideo
          open={showYTVideo}
          onClose={() => {
            setShowYTVideo(false);
          }}
          videoSrc="https://www.youtube.com/embed/moqfztNmP_I"
        />
      )}
    </main>
  );
};

export default FundingInsights;
