import React, { useEffect, useState } from "react";
import iconCheck from "../../assets/images/icons/icon-check.svg";

const CommonfeaturesListData = [
  {
    listItem: "Send 50 Emails/Day",
  },
  {
    listItem: "Auto-Remove From Spam",
  },
  {
    listItem: "Auto-Reply To Emails",
  },
  {
    listItem: "Mark Emails As Favorite",
  },
  {
    listItem: "Sender Reputation Monitoring",
  },
  {
    listItem: "Custom Warm-up Settings",
  },
  {
    listItem: "DNS & Blacklist Monitoring",
  },
];
const InboxIntegrationsListData = [
  {
    listItem: "Gmail - Gsuite Inbox (OAuth)",
  },
  {
    listItem: "Outlook - Microsoft 365",
  },
  {
    listItem: "Zoho Inbox",
  },
  {
    listItem: "Yahoo Inbox",
  },
  {
    listItem: "Amazon SES Inbox",
  },
  {
    listItem: "Any Other Inbox (SMTP)",
  },
];
const CustomerSupportListData = [
  {
    listItem: "In-app Onboarding",
  },
  {
    listItem: "Help Center",
  },
  {
    listItem: "Live Chat Support",
  },
  {
    listItem: "24/7 Email Support",
  },
  {
    listItem: "Data Deletion",
  },
  {
    listItem: "Blog Resources",
  },
];

const PricingCard = () => {
  const [width, setWindowWidth] = useState(0);
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  return (
    <section className="relative pb-8 lg:pb-16">
      <div className="container mx-auto">
        <div className="lg:w-11/12 xl:w-10/12 mx-auto pricing-card-container">
          <div className="flex justify-between flex-col md:flex-row pb-6 border-[#BFCEDB] border-b">
            <div className="w-full md:w-5/12">
              <h2 className="text-6xl lg:text-[5.25rem] font-extrabold text-center">
                {/* <span className="font-bold" style={{ color: "#B6B6B6" }}>
                    Billed monthly
                  </span>
                  <div className="switch-container">
                    <input
                      id="switch"
                      type="checkbox"
                      className="switch-input"
                    />
                    <label
                      for="switch"
                      className="switch-label switch-label--sm"
                    ></label>
                  </div> */}
                $15<span className="secondary-font text-xl">/month</span>
              </h2>
              {width < 640 ? (
                <>
                  <p className="text-secondary text-center opacity-70 mt-2 letter-space02">
                    7-day free trial <br className="hidden xl:block"></br> $15
                    USD/inbox/month
                  </p>
                </>
              ) : (
                <>
                  <p className="text-secondary text-center opacity-70 mt-2 letter-space02">
                    7-day free trial then $15 USD/inbox/month
                    <br className="hidden xl:block"></br>All features included.
                  </p>
                </>
              )}
              {/* <p className="text-secondary md:text-xl text-center opacity-70 mt-2 lg:mt-4 line-height4 letter-space02">
                7-day free trial then $15 USD/inbox/month All features included.
              </p> */}
            </div>
            <div className="w-full md:w-6/12 mt-4 sm:mt-0">
              <div className="flex flex-col items-center justify-center lg:items-start">
                <h3 className="text-xl md:text-2xl lg:text-[2rem] font-extrabold text-center lg:text-left lg-line-height3">
                  Skyrocket the results of <br className="hidden xl:block"></br>{" "}
                  your cold email campaigns.
                </h3>
                <p className="text-secondary mt-4 text-center lg:text-left letter-space02">
                  Join thousands of warm email inboxes today.
                </p>
                <div className="flex flex-col w-full mt-4 sm:mt-0">
                  <button
                    onClick={() =>
                      (window.location.href = "https://app.revli.com/register")
                    }
                    className="btn btn--primary text-lg font-bold py-3 md:py-4 px-12 mt-3 lg:w-full"
                  >
                    Start 7-day Free Trial
                  </button>
                  <span className="w-full text-primary-brand-dark font-bold text-center letter-space02 mt-3">
                    No credit card required.
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* <h5 className="pricing-features-list__title">Features</h5> */}
          <div className="overflow-x-auto scrollbar-none overflow-x-behaviour-contain">
            <div className="w-[56.25rem] md:w-auto flex justify-between -mx-3 pt-12">
              <div className="w-4/12 px-3 flex flex-col">
                {CommonfeaturesListData.map((item, index) => (
                  <li
                    key={`${index}`}
                    className="flex items-center mb-[0.625rem]"
                  >
                    <img
                      src={iconCheck}
                      alt="icon-check"
                      className="w-[1.266rem] h-[1.266rem]"
                    />
                    <p className="text-secondary font-medium letter-space02 ml-3">
                      {item.listItem}
                    </p>
                  </li>
                ))}
              </div>
              <div className="w-4/12 px-3 flex flex-col">
                {InboxIntegrationsListData.map((item, index) => (
                  <li key={index} className="mb-[0.625rem] flex items-center">
                    <img
                      src={iconCheck}
                      alt="icon-check"
                      className="w-[1.266rem] h-[1.266rem]"
                    />
                    <p className="text-secondary font-medium letter-space02 ml-3">
                      {item.listItem}
                    </p>
                  </li>
                ))}
              </div>
              <div className="w-4/12 px-3 flex flex-col">
                {CustomerSupportListData.map((item, index) => (
                  <li key={index} className="mb-[0.625rem] flex items-center">
                    <img
                      src={iconCheck}
                      alt="icon-check"
                      className="w-[1.266rem] h-[1.266rem]"
                    />
                    <p className="text-secondary font-medium letter-space02 ml-3">
                      {item.listItem}
                    </p>
                  </li>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="pricing-features-list-container">
                <h5 className="pricing-features-list__title">
                  Inbox Integrations
                </h5>
                <ul className="mt-10">
                  {InboxIntegrationsListData.map((item, index) => (
                    <li key={index} className="pricing-features-list__item">
                      <img src={iconCheck} alt="icon-check" />
                      <p className="md:text-lg font-medium">{item.listItem}</p>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="pricing-features-list-container">
                <h5 className="pricing-features-list__title">
                  Customer Support
                </h5>
                <ul className="mt-10">
                  {CustomerSupportListData.map((item, index) => (
                    <li key={index} className="pricing-features-list__item">
                      <img src={iconCheck} alt="icon-check" />
                      <p className="md:text-lg font-medium">{item.listItem}</p>
                    </li>
                  ))}
                </ul>
              </div> */}
        </div>
      </div>
    </section>
  );
};

export default PricingCard;
