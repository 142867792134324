import React from "react";
import adaptive from "../assets/images/icons/adaptive.svg";
import meetingSchedule from "../assets/images/icons/meeting-schedule.svg";
import telegram from "../assets/images/icons/telegram.svg";
import timeResources from "../assets/images/icons/time-resources.svg";
import noResults from "../assets/images/icons/no-results.svg";
import postBox from "../assets/images/icons/post-box.svg";

const MeetingScheduleFeatures = () => {
  const fundingInsightsListData = [
    {
      img: telegram,
      title: "You're seeing low meeting conversion rates.",
      desc: "Our lead qualification process screens each potential customer, ensuring you engage only with prospects who are most likely to convert.",
    },
    {
      img: timeResources,
      title: "You're short on time and resources to build a sales team.",
      desc: "Eliminate the overhead of recruitment and training by partnering with Revli; consider us an extension of your team dedicated to clinching deals.",
    },
    {
      img: meetingSchedule,
      title: "Your meeting schedule is unpredictable.",
      desc: "We specialize in creating consistent engagement, keeping your calendar filled with promising leads and ensuring a steady stream of deals.",
    },
    {
      img: noResults,
      title: "Your outreach isn't producing results.",
      desc: "Leverage our deep expertise in multichannel outreach to improve your acquisition efforts, capturing leads across a wider spectrum.",
    },
    {
      img: postBox,
      title: "You're seeing few meetings due to low reply rates.",
      desc: "Improve your reply rates with our engagement-driven outreach, designed to resonate and convert, filling your schedule with quality discussions.",
    },
    {
      img: adaptive,
      title: "You're looking for faster user and revenue growth.",
      desc: "We deploy swift, effective strategies for user acquisition, meeting scheduling and revenue growth that align with your ambitious goals.",
    },
  ];
  return (
    <section className="pt-[3.875rem] pb-[3.875rem] lg:pb-[6.25rem] relative">
      <div className="container mx-auto">
        <h2 className="text-2xl md:text-3xl lg:text-[2.875rem] font-extrabold line-height3 text-center">
          The challenges slowing down <br className="hidden lg:block"></br> your
          revenue growth
        </h2>
        <div className="mt-10 lg:mt-[7rem] relative">
          <ul className="flex lg:flex-wrap -mx-3 overflow-x-auto pt-8 lg:pt-0 lg-overflow-x-initial scrollbar-none overflow-x-behaviour-contain">
            {fundingInsightsListData.map((item, index) => (
              <li
                key={index}
                className="w-11/12 lg:w-4/12 flex-shrink-0 lg-flex-shrink-initial px-3 mb-6"
              >
                <div className="h-full card-box">
                  <figure className="fig-wrapper">
                    <img src={item.img} alt="icon-telegram" />
                  </figure>
                  <div className="mt-8 lg:mt-[3.375rem] flex flex-col">
                    <h5 className="text-lg lg:text-xl font-bold">
                      {item.title}
                    </h5>
                    <p className="secondary-font text-secondary lg:text-lg mt-[0.625rem] letter-space02">
                      {item.desc}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default MeetingScheduleFeatures;
