import React, { useState } from "react";
import spamTestBg from "../assets/images/spam-test-hero-bg.jpg";
import CHOICES from "../CHOICES";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import ModalRunNewTest from "../components/SpamTestPage/ModalRunNewTest";
import SpamTestHero from "../components/SpamTestPage/SpamTestHero";
import SpamTestHomeSolutions from "../components/SpamTestPage/SpamTestHomeSolutions";
import SpamTestPageFeatures from "../components/SpamTestPage/SpamTestPageFeatures";
import Testimonials from "../components/Testimonials";
import HeaderNew from "../components/HeaderNew";

const EmailSpamTest = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <main className="email-spam-test">
      <section>
        {/* <Header /> */}
        <HeaderNew />
        <SpamTestHero setOpenModal={setOpenModal} />
        <section className="relative -mt-[100px]">
          <div className="main-container mx-auto px-8">
            <img
              src={spamTestBg}
              alt="spam-test-bg"
              style={{ borderRadius: 18 }}
            />
          </div>
        </section>
        <SpamTestPageFeatures />
        <SpamTestHomeSolutions setOpenModal={setOpenModal} />
        <section className="py-[3.875rem]">
          <FAQ topic={CHOICES.FAQTopic.EMAIL_SPAM_TEST} />
        </section>
        <Testimonials />
        <GetStarted />
        <Footer />
      </section>
      <ModalRunNewTest openModal={openModal} setOpenModal={setOpenModal} />
    </main>
  );
};

export default EmailSpamTest;
