import React from "react";
import heroInboxPerformance from "../assets/images/hero-pic-inbox-performance.png";
import ellipseHeroPic from "../assets/images/ellipse-hero-bg.svg";

const EmailWarmupHero = () => {
  return (
    <section className="hero-home pt-[7rem] sm:pt-[10rem] lg:pt-[12rem]">
      <div className="relative main-container flex flex-col lg:flex-row z-[1]">
        <div className="hero-home__left">
          <h1 className="heading-level1 flex flex-col leading-tight">
            <span>More Replies.</span>
            <span>More Revenue.</span>
          </h1>
          <p
            className="text-white text-base md:text-xl mt-5 letter-space02 lg:pr-20"
            style={{ color: "rgba(255,255,255,0.7)" }}
          >
            Land in the inbox. Not the spam folder. Revli users double their
            open rates, reply rates and meetings booked.
          </p>
          <div className="flex flex-col text-center mt-8 w-full md:w-6/12">
            <button
              className="btn btn--primary font-bold lg:text-lg py-3"
              onClick={() =>
                (window.location.href = "https://app.revli.com/register")
              }
            >
              Start 7-Day Free Trial
            </button>
            <span className="secondary-font text-white text-xs font-extra-bold mt-2">
              No credit card required.
            </span>
          </div>
        </div>
        <figure className="hero-home__banner-pic mt-8 lg:mt-0">
          <img src={heroInboxPerformance} alt="hero-inbox-performance" />
        </figure>
      </div>

      <img
        src={ellipseHeroPic}
        alt="ellipse-hero-bg" 
        className="hidden lg:block xxl:hidden absolute top-0 right-0"
      />
    </section>
  );
};

export default EmailWarmupHero;
