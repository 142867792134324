import React, { useEffect, useState } from "react";
import iconCheck from "../../assets/images/icons/icon-check.svg";

const meetingSchedulingPlansData = [
  "Qualified Leads & Meetings",
  "Industry-Specific Targeting",
  "Persona-Specific Targeting",
  "Market & ICP Analysis",
  "Personalized Messaging",
  "A/B Testing & Optimization",
];
const meetingSchedulingPlansData1 = [
  "No-Show Follow Up",
  "Multi-Channel Outreach",
  "Customer Segmentation",
  "Schedule Coordination",
  "CRM Integration",
  "Detailed Reporting & Insights",
];

const PricingCardMeetingScheduling = () => {
  const [width, setWindowWidth] = useState(0);
  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  return (
    <section className="relative z-[1] pb-8 lg:pb-16">
      <div className="container mx-auto">
        <div className="lg:w-11/12 xl:w-10/12 mx-auto pricing-card-container">
          <div className="flex justify-between flex-col md:flex-row pb-6  border-b border-[#BFCEDB]">
            <div className="w-full md:w-5/12">
              <h2 className="text-6xl lg:text-[5.25rem] font-extrabold text-center">
                {/* <span className="font-bold" style={{ color: "#B6B6B6" }}>
                    Billed monthly
                  </span>
                  <div className="switch-container">
                    <input
                      id="switch"
                      type="checkbox"
                      className="switch-input"
                    />
                    <label
                      for="switch"
                      className="switch-label switch-label--sm"
                    ></label>
                  </div> */}
                $2450<span className="secondary-font text-xl">/month*</span>
              </h2>
              <p className="text-primary-brand-dark font-bold letter-space02 text-center opacity-70 mt-4">
                3 month commitment*
              </p>
              <p className="text-secondary letter-space02 text-center mt-3">
                Revli brings you qualified sales meetings, so you can focus on
                closing the deal.
              </p>
            </div>
            <div className="w-full md:w-6/12 mt-4 sm:mt-0">
              <div className="flex flex-col items-center justify-center lg:items-start">
                <h3 className="text-xl md:text-2xl lg:text-[2rem] font-extrabold text-center lg:text-left lg-line-height3">
                  Stop prospecting.<br></br>
                  Start closing.
                </h3>
                <p className="text-secondary mt-4 text-center lg:text-left letter-space02">
                  We generate, you convert. High quality meetings delivered.
                </p>
                <div className="flex flex-col w-full mt-4 sm:mt-0">
                  <button
                    onClick={() => {
                      window.open("https://calendly.com/revli", "_blank");
                    }}
                    className="btn btn--primary text-lg font-bold py-3 md:py-4 px-12 mt-3 lg:w-full"
                  >
                    Schedule A Call
                  </button>
                  <span className="w-full text-primary-brand-dark font-bold text-center letter-space02 mt-3">
                    Book your free consultation.
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* <h5 className="pricing-features-list__title">Features</h5> */}
          <div className="overflow-x-auto scrollbar-none overflow-x-behaviour-contain">
            <div className="w-[30.25rem] md:w-auto flex justify-between lg:justify-evenly -mx-3 pt-12">
              <div className="w-6/12 md:w-4/12 px-3 flex flex-col">
                {meetingSchedulingPlansData.map((item, index) => (
                  <li
                    key={`${index}`}
                    className="flex items-center mb-[0.625rem]"
                  >
                    <img
                      src={iconCheck}
                      alt="icon-check"
                      className="w-[1.266rem] h-[1.266rem]"
                    />
                    <p className="text-secondary font-medium letter-space02 ml-3">
                      {item}
                    </p>
                  </li>
                ))}
              </div>
              <div className="w-6/12 md:w-4/12 px-3 flex flex-col">
                {meetingSchedulingPlansData1.map((item, index) => (
                  <li
                    key={`${index}`}
                    className="flex items-center mb-[0.625rem]"
                  >
                    <img
                      src={iconCheck}
                      alt="icon-check"
                      className="w-[1.266rem] h-[1.266rem]"
                    />
                    <p className="text-secondary font-medium letter-space02 ml-3">
                      {item}
                    </p>
                  </li>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="pricing-features-list-container">
                <h5 className="pricing-features-list__title">
                  Inbox Integrations
                </h5>
                <ul className="mt-10">
                  {InboxIntegrationsListData.map((item, index) => (
                    <li key={index} className="pricing-features-list__item">
                      <img src={iconCheck} alt="icon-check" />
                      <p className="md:text-lg font-medium">{item.listItem}</p>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="pricing-features-list-container">
                <h5 className="pricing-features-list__title">
                  Customer Support
                </h5>
                <ul className="mt-10">
                  {CustomerSupportListData.map((item, index) => (
                    <li key={index} className="pricing-features-list__item">
                      <img src={iconCheck} alt="icon-check" />
                      <p className="md:text-lg font-medium">{item.listItem}</p>
                    </li>
                  ))}
                </ul>
              </div> */}
        </div>
      </div>
    </section>
  );
};

export default PricingCardMeetingScheduling;
