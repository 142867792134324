import React, { useState } from "react";
import HeaderNew from "../components/HeaderNew";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import PricingHero from "../components/Pricing/PricingHero";
import PricingCard from "../components/Pricing/PricingCard";
import Testimonials from "../components/Testimonials";
import GetStarted from "../components/GetStarted";
import CHOICES from "../CHOICES";
import PricingCardFundingInsights from "../components/Pricing/PricingCardFundingInsights";
import PricingCardMeetingScheduling from "../components/Pricing/PricingCardMeetingScheduling";

const Pricing = () => {
  const [isPriceCard, setIsPriceCard] = useState("Funding Insights");

  const pricingCards = {
    "Funding Insights": <PricingCardFundingInsights />,
    "Email warmup": <PricingCard />,
    "Meeting Scheduling": <PricingCardMeetingScheduling />,
  };

  const faqTopics = {
    "Funding Insights": CHOICES.FAQTopic.FUNDING_INSIGHTS_PRICING,
    "Email warmup": CHOICES.FAQTopic.EMAIL_WARMUP_PRICING,
    "Meeting Scheduling": CHOICES.FAQTopic.MEETING_SCHEDULING_PRICING,
  };

  return (
    <main className="">
      <section className="pricing-page">
        <HeaderNew />
        <PricingHero
          setIsPriceCard={setIsPriceCard}
          isPriceCard={isPriceCard}
        />
        {pricingCards[isPriceCard]}
        <section className="py-16 lg:pt-[3.875rem] lg:pb-[7.75rem]">
          <FAQ topic={faqTopics[isPriceCard]} />
        </section>
        <Testimonials />
        <GetStarted />
        <Footer />
      </section>
    </main>
  );
};

export default Pricing;
