import React from "react";
import iconCheck from "../../assets/images/icons/icon-check.svg";

const PricingCardFundingInsights = () => {
  const growthPlansFeatures = [
    "2500+ Leads/Month",
    "New Leads Every Week",
    "Founder & C-Suite Contact Info",
    "Decision-Maker Contact Info",
    "Verified Contact Emails",
    "Verified LinkedIn Profiles",
    "Full Contact Profiles",
    "Recent Funding Amount",
    "Recent Funding Details",
    "Recent Funding News",
    "Revenue Data",
    "Location Data",
    "Industry Data",
    "Technographic Data",
    "Investor Data",
    "Company & Contact Filters",
    "Live Chat Support & Help Center",
    "Unlimited Search & Filter",
  ];
  const growthPlansFeatures1 = [
    "2500+ Leads/Month",
    "New Leads Every Week",
    "Founder & C-Suite Contact Info",
    "Decision-Maker Contact Info",
    "Verified Contact Emails",
    "Verified LinkedIn Profiles",
    "Full Contact Profiles",
    "Recent Funding Amount",
    "Recent Funding Details",
    "Recent Funding News",
    "Revenue Data",
    "Location Data",
    "Industry Data",
    "Technographic Data",
    "Investor Data",
    "Company & Contact Filters",
    "Live Chat Support & Help Center",
    "Unlimited Search & Filter",
    "Export Leads to CSV",
  ];

  return (
    <section className="relative pb-8 lg:pb-16">
      <div className="container mx-auto">
        <div className="w-full lg:w-11/12 xl:w-10/12 mx-auto pricing-card-container pricing-card-container--funding-insights">
          <div className="overflow-x-auto scrollbar-none md-overflow-x-initial overflow-x-behaviour-contain">
            <div className="w-[45.5rem] md:w-auto flex -mx-3 lg:-mx-6">
              <div className="w-11/12 md:w-6/12 px-3 lg:px-6">
                <div className="h-full p-8 bg-white rounded-xl shadow-[0px_12px_32px_0px_rgba(147,180,210,0.25)]">
                  <h2 className="text-2xl lg:text-[2rem] font-extrabold lg-line-height3 text-center">
                    Growth
                  </h2>
                  <p className="text-secondary text-center letter-space02 mt-4">
                    Search and filter your leads
                  </p>
                  <div className="secondary-font text-4xl md:text-[4.25rem] lg:text-[5.25rem] font-extrabold text-center mt-1 md-line-height3">
                    $79<span className="text-lg lg:text-xl">/month</span>
                  </div>
                  <p className="text-secondary text-center letter-space02 mt-3 opacity-70">
                    Instant access to 4 weeks of leads, plus weekly
                    <br className="hidden md:block"></br> reports with over 2500
                    new leads/month.
                  </p>
                  <div className="flex flex-col items-center pb-6 border-b border-[#BFCEDB">
                    <button
                      className="btn btn--primary btn--h52 w-full lg:text-lg font-bold px-4 py-1 mt-4"
                      onClick={() =>
                        (window.location.href =
                          "https://app.revli.com/register")
                      }
                    >
                      Get your first report free
                    </button>
                    <span className="text-primary-brand-dark font-bold letter-space02 mt-3">
                      No credit card required.
                    </span>
                  </div>
                  <ul className="mt-10">
                    {growthPlansFeatures.map((item, index) => (
                      <li
                        key={`${index}`}
                        className="flex items-center mb-[0.625rem]"
                      >
                        <img
                          src={iconCheck}
                          alt="icon-check"
                          className="w-[1.266rem] h-[1.266rem]"
                        />
                        <p className="text-secondary text-sm font-medium letter-space02 ml-3">
                          {item}
                        </p>
                      </li>
                    ))}
                  </ul>
                  <div className="flex items-center">
                    <div className="flex items-center justify-center w-5 h-5">
                      <svg
                        width="12"
                        height="13"
                        viewBox="0 0 14 15"
                        fill="none"
                      >
                        <path
                          d="M12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91L12.59 0.5Z"
                          fill="#DB584F"
                        />
                      </svg>
                    </div>

                    <p className="text-secondary text-sm font-medium letter-space02 ml-3">
                      Export Leads to CSV
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-11/12 md:w-6/12 px-3 lg:px-6">
                <div className="h-full p-8 bg-white rounded-xl shadow-[0px_12px_32px_0px_rgba(147,180,210,0.25)]">
                  <h2 className="text-2xl lg:text-[2rem] font-extrabold lg-line-height3 text-center">
                    Pro
                  </h2>
                  <p className="text-secondary text-center letter-space02 mt-4">
                    Search, filter and export your leads
                  </p>
                  <div className="secondary-font text-4xl md:text-[4.25rem] lg:text-[5.25rem] font-extrabold text-center mt-1 md-line-height3">
                    $149<span className="text-lg lg:text-xl">/month</span>
                  </div>
                  <p className="text-secondary text-center letter-space02 mt-3 opacity-70">
                    Instant access to 4 weeks of leads, plus weekly
                    <br className="hidden md:block"></br> reports with over 2500
                    new leads/month.
                  </p>
                  <div className="flex flex-col items-center pb-6 border-b border-[#BFCEDB">
                    <button
                      className="btn btn--primary btn--h52 w-full lg:text-lg font-bold px-4 py-1 mt-4"
                      onClick={() =>
                        (window.location.href =
                          "https://app.revli.com/register")
                      }
                    >
                      Get your first report free
                    </button>
                    <span className="text-primary-brand-dark font-bold letter-space02 mt-3">
                      No credit card required.
                    </span>
                  </div>
                  <ul className="mt-10">
                    {growthPlansFeatures1.map((item, index) => (
                      <li
                        key={`${index}`}
                        className="flex items-center mb-[0.625rem]"
                      >
                        <img
                          src={iconCheck}
                          alt="icon-check"
                          className="w-[1.266rem] h-[1.266rem]"
                        />
                        <p className="text-secondary text-sm font-medium letter-space02 ml-3">
                          {item}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingCardFundingInsights;
