import React from "react";
import iconAI from "../assets/images/ai-pic.png";
import iconEmailProvider from "../assets/images/email-provider-pic.png";
import iconEmailContent from "../assets/images/email-content-pic.png";
import iconAutopilot from "../assets/images/autopilot-pic.png";
import iconDashboard from "../assets/images/track-progress-pic.png";
import iconDomainMonitoring from "../assets/images/domain-monitoring-pic.png";

const solutionsListData = [
  {
    img: iconAI,
    title: "Smart AI-powered warming algorithm",
    subTitle:
      "Revli uses intelligent warming algorithms to enhance your sender score and email deliverability while improving and maintaining domain reputation.",
  },
  {
    img: iconEmailProvider,
    title: "Compatible with every major email provider",
    subTitle:
      "Revli works seamlessly with all popular email service providers like Gmail, Outlook/Office 365, and Zoho and supports any custom providers via SMTP.",
  },
  {
    img: iconEmailContent,
    title: "Realistic email content & intelligent sending behaviour",
    subTitle:
      "Your warm-up emails won't just be opened and starred. Instead, our intelligent AI will craft realistic same-thread conversations while optimizing email sending patterns for the best results.",
  },
  {
    img: iconAutopilot,
    title: "Set-and-forget. Runs 100% on autopilot",
    subTitle:
      "The whole warming process is taken care of for you. We do all the heavy lifting with no technical skills required. Focus on engaging with your leads while your deliverability grows.",
  },
  {
    img: iconDashboard,
    title: "Detailed reporting dashboard to track your progress",
    subTitle:
      "Track and control Revli on an easy-to-use dashboard. Get insights on your daily activity and email/inbox deliverability. Adjust or pause your sending schedule when needed.",
  },
  {
    img: iconDomainMonitoring,
    title: "Blacklists, deliverability & domain reputation monitoring",
    subTitle:
      "Revli monitors your sender score, blacklists, domain reputation and deliverability with real-time alerts and recommendations to build and maintain your inbox health.",
  },
];

const HomeSolutions = () => {
  return (
    <section className="solutions-section">
      <div className="main-container">
        <h2 className="w-full lg:w-9/12 heading-level2 text-center mx-auto">
          The most advanced and effective solution to warm-up your inbox safely
        </h2>
      </div>
      <div className="scroll-x-wrapper">
        <div className="main-container">
          <ul className="solutions-section__list-wrapper mt-8 md:mt-16">
            {solutionsListData.map((item, index) => (
              <li key={index} className="solutions-section__list">
                <div className="solutions-section__list__container">
                  <figure className="solutions-section__list__icon-wrapper">
                    <img src={item.img} alt="icon-telegram" />
                  </figure>
                  <h3 className="solutions-section__list__title">
                    {item.title}
                  </h3>
                  <p className="solutions-section__list__desc">
                    {item.subTitle}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default HomeSolutions;
