import React, { useState } from "react";
import CHOICES from "../CHOICES";
import Accordion from "../components/Accordion";
import HeroCommon from "../components/common/hero-section";
import Footer from "../components/Footer";
import HeaderNew from "../components/HeaderNew";

const FAQ = () => {
  const [search, setSearch] = useState("");
  return (
    <main className="">
      <section>
        {/* <Header /> */}
        <HeaderNew />
        <HeroCommon
          title={"Frequently Asked Questions"}
          subTitle={
            "Have a question? You're not the only one! See our most common Q&As below."
          }
        />
        <section className="relative pb-[6.25rem] -mt-[200px]">
          <div className="main-container">
            <div className="card-section p-5 lg:p-12">
              <input
                type="text"
                className="input-field input-field--xs input-field--search"
                placeholder="Select Field"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="mt-12">
                <Accordion search={search} topic={CHOICES.FAQTopic.FAQ} />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </section>
    </main>
  );
};

export default FAQ;
